import {
    Box,
    Button,
    FormControl,
    HStack,
    Image,
    Input,
    Stack,
    Text,
    VStack,
  } from "@chakra-ui/react";
  import React, { useEffect, useState, useRef } from "react";
  import { Img } from "../../../content/Content";
  import i18n from "../../../i18n/config";
  import { firestore } from "../../../util/firebase";
  import {
    collection,
    addDoc,
    doc,
    orderBy,
    onSnapshot,
    query,
    getDocs,
    getDoc,
    limit,
    startAfter,
    deleteDoc,
  } from "firebase/firestore";
  import { useParams } from "react-router-dom";
  import PropTypes from "prop-types";
  
  import BroadCastInfo from "./BroadCastInfo";
  
  const LiveChat = (props) => {
    const { educator, session, administrator } = props;
  
    const { sessionId } = useParams();
    const [currentUser, setCurrentUser] = useState("");
    const [messages, setMessages] = useState([]);
    const [sessionLive, setSessionLive] = useState(false);
    const [newMessage, setNewMessage] = useState("");
    const [allMessages, setAllMessages] = useState([]);
    const [allInitialMessages, setAllInitialMessages] = useState([]);
    const [chatroomActive, setChatroomActive] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const messagesEndRef = useRef(null);
  
    // Preinstantiated Values
    const documentFetchLimit = 50;
    const [firstDocumentRef, setFirstDocumentRef] = useState();
    const [hasSetInitialMessages, setHasSetInitialMessages] = useState(false);
    const [chatroomPrefs, setChatroomPrefs] = useState({});
    const [lastSelectedChatroom, setLastSelectedChatroom] = useState("");

    // DeadSimpleChatConfiguration
    const [chatSDK, setChatSDK] = useState();
    const [currentChatroomID, setCurrentChatroomID] = useState('');
    const [currentChatroomUserID, setCurrentChatroomUserID] = useState('');
  
    // Chatroom related methods

    const fetchChatroom = async () => {
      if (sessionId !== null && sessionId !== undefined) {
        const documentRef = doc(firestore, 'sessions', sessionId);
        await getDoc(documentRef).then(async (documentSnapshot) => {
          if (documentSnapshot.exists() === true) {
            const documentData = documentSnapshot.data();
            const educator_id = documentData.educator_id ?? '';
            const chatroom_status = documentData.chat_enabled ?? false;
            const educatorDocRef = doc(firestore, 'educators', educator_id);

            setChatroomActive(chatroom_status);
            await getDoc(educatorDocRef).then((educatorDocumentSnapshot) => {
              if (educatorDocumentSnapshot.exists() === true) {
                const educatorDocData = educatorDocumentSnapshot.data();
                const chatroom_id = educatorDocData.chatroom_id ?? '';
                if (chatroom_id !== '') {
                  setCurrentChatroomID(chatroom_id);
                  fetchCurrentUser();
                } else {
                  console.log('Chatroom hasnt been configured');
                }
              };
            });
          };
        });
      }
    };

    const clearChatroom = async () => {
      try {
        const options = {
          method: 'DELETE',
          headers: {
            'origin': '*',
            'x-requested-with': '*',
            'Content-Type': 'application/json',
            'jifuapi-auth-key': '31cd9073-bbe0-430e-831b-000e73b1a6c8'
          },
          body: {
            roomId: currentChatroomID
          }
        };
  
        const response = await fetch(`https://corsavailable.herokuapp.com/https://jifulive-api-8743a04c5191.herokuapp.com/chatroom/messages`, options);
  
        console.log(response.status);
        if (response.status === 200) {
          window.location.reload();
        } else{
          console.log('Failed to clear chatroom')
        };
      } catch (error) {
        console.error('An error occurred:', error);
      };
    };
  
    const fetchCurrentUser = () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user != undefined && user != null) {
        setCurrentUser(user.id);
        fetchDeadSimpleChatUser(user.id);
      }
    };

    const fetchDeadSimpleChatUser = async (currentUserID) => {
      if (hasSetInitialMessages === false) {
        try {
          const options = {
            method: 'GET',
            headers: {
              'origin': '*',
              'x-requested-with': '*',
              'Content-Type': 'application/json',
              'jifuapi-auth-key': '31cd9073-bbe0-430e-831b-000e73b1a6c8'
            }
          };
    
          const response = await fetch(`https://corsavailable.herokuapp.com/https://jifulive-api-8743a04c5191.herokuapp.com/chat/user/${currentUserID + ''}`, options);
    
          console.log(response.status);
          if (response.status === 200) {
            setCurrentChatroomUserID(currentUserID);
            setupDeadSimpleChat();
            console.log('Successfully fetched user. No need to create.');
          } else if (response.status === 404) {
            createDeadSimpleUserIfNotFound(currentUserID);
          };
  
        } catch (error) {
          console.error('An error occurred:', error);
        };
        setHasSetInitialMessages(true);
      }
    };

    const createDeadSimpleUserIfNotFound = async (currentUserID) => {
      try {
        const options = {
          method: 'POST',
          headers: {
            'origin': '*',
            'x-requested-with': '*',
            'Content-Type': 'application/json',
            'jifuapi-auth-key': '31cd9073-bbe0-430e-831b-000e73b1a6c8'
          },
          body: JSON.stringify({
            jifuId: currentUserID
          }),
        };
  
        const response = await fetch(`https://corsavailable.herokuapp.com/https://jifulive-api-8743a04c5191.herokuapp.com/chat/user`, options);
  
        if (response.status === 200) {
          setCurrentChatroomUserID(currentUserID);
          console.log('Successfully created user.')
          setupDeadSimpleChat();
        };
      } catch (error) {
        console.error('An error occurred:', error);
      };
    };

    const setupDeadSimpleChat = async () => {
      // Initialize new DSChat SDK instance
      let DSChatSDK = window.DSChatSDK;
      if (currentChatroomID !== '') {
        const sdk = new DSChatSDK(currentChatroomID, "chat-frame", "pub_4d464c74516556475065773956587575666f5561334574416c74646c713935467738726346383159655f6d6232447653");
        // Call the connect method to connect the SDK to the Chat iFrame.
        setChatSDK(sdk);

        chatSDK.loadCustomization({
          sidebarColor: "#ff3",
          chatMessageFont: "Nunito",
          hideHeader: true,
          hideOnlineUserCounter: true
        });

        await chatSDK.connect();
       
      };
    };
  
    useEffect(() => {
      fetchChatroom();
    }, []);
  
    return (
      <div>
        {/* Top Bar */}
        {administrator !== undefined && administrator === true && (
          <Stack
            w="100%"
            pb="40px"
            borderRadius="26px"
            bg={"rgba(255, 255, 255, 1)"}
            mx="30px"
            mt="50px"
            mb="50px"
            className="desktop-version"
          >
            <BroadCastInfo
              session={session}
              administrator={administrator}
              educator={educator}
            />
          </Stack>
        )}
        <Stack
          w="100%"
          pb="40px"
          borderRadius="26px"
          bg={"rgba(255, 255, 255, 1)"}
          mx="30px"
          mt="50px"
          mb="50px"
          className="desktop-version"
        >
          {/* Top Bar */}
  
          <Stack
            justifyContent={"flex-start"}
            alignItems={"center"}
            flexDir="row"
            w="100%"
            p={"15px 25px"}
            mt="20px"
            flexWrap="wrap"
            flexDirection={"column"}
          >
            <Image
              src={chatroomActive === true ? Img.chat : Img.chatOffline}
              width="13px"
              h="25px"
            />
            <HStack flex={1} justifyContent={'space-between'}>
            <Text
              fontFamily="ProximaNova"
              fontSize="20px"
              fontWeight="800"
              color="rgba(41, 45, 50, 1)"
            >
              {i18n.t("live_chat")}
            </Text>
            {administrator !== undefined && administrator === true && (
                <Button 
                onClick={() => clearChatroom()}
                fontFamily="ProximaNova"
                textColor={'black'}
                fontSize="14px"
                fontWeight="500" h={'40px'} w={'auto'} px={'10px'} backgroundColor={'#C7C7C7'} borderRadius={'10px'} alignContent={'end'} alignSelf={'end'}> Clear Chat</Button>)
            }
            </HStack>
            <Box
              borderBottom="1px solid rgba(236, 236, 236, 1)"
              w="100%"
              py="10px"
            ></Box>
          </Stack>
          {/*  Chat Box */}
          <Stack
            alignItems="center"
            justifyContent="flex-start"
            w="100%"
            height={"350"}
            minHeight={"500"}
          >
            {chatroomActive === true && currentChatroomID !== '' && currentChatroomUserID !== '' && (
              <iframe id="chat-frame" src={`https://deadsimplechat.com/${currentChatroomID}?uniqueUserIdentifier=${currentChatroomUserID}`} width="100%" height="600px"></iframe>
            )}
            {chatroomActive === false && (
              <>
              <Stack h="100%" w="100%" alignItems={'center'}>
              <Text my={'auto'} align={'center'} alignContent={'center'}>
                Chatroom Disabled
              </Text>
              </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </div>
    );
  };
  
  export default LiveChat;
  
  LiveChat.propTypes = {
    educator: PropTypes.any,
    session: PropTypes.any,
    administrator: PropTypes.bool,
  };
  