import {
  Box,
  Container,
  Image,
  Stack,
  Text,
  VStack,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import Slider from "react-slick";
import { Img } from "../../../../content/Content";
import "./Feature.component.css";

import { firestore } from "../../../../util/firebase";
import { collection, getDocs, where, query } from "firebase/firestore";

import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n/config";
import { currentLanguage } from "../../../../util/language";
import { useRouter } from "../../../../hooks/use-router";

const Feature = () => {
  const router = useRouter();

  const { t } = useTranslation();
  const [allAcademies, setAllAcademies] = useState([]);
  const [featured, setFeatured] = useState([]);

  const overlayColor = "#0071C1"; // Default color
  const overlayOpacity = 0.55;

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    nav: true,
    nextArrow: <FaAngleRight />,
    prevArrow: <FaAngleLeft />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [showSlide, setSlide] = useState(false);
  const productSide = showSlide ? featured.slice(1, 10) : featured.slice(1, 4);

  const fetchAllAcademies = async () => {
    const educatorsDocRef = collection(firestore, "academy");
    const academyDocumentSnapshots = await getDocs(educatorsDocRef);

    if (academyDocumentSnapshots.empty === false) {
      const parsedAcademies = academyDocumentSnapshots.docs.map(
        (academyDoc) => {
          const academyData = academyDoc.data();
          const academy = {
            id: academyDoc.id,
            logo: academyData.logo,
            name: academyData.title,
            color: academyData.color ?? "#0071C1",
          };
          return academy;
        }
      );
      setAllAcademies(parsedAcademies);
      fetchFeaturedForLanguage();
    } else {
      //
    }
  };

  const fetchFeaturedForLanguage = async () => {
    const q = query(
      collection(firestore, "educators"),
      where("language", "==", currentLanguage().id ?? "en")
    );
    try {
      const allDocs = await getDocs(q);
      if (allDocs.empty === false) {
        const parsedSessions = allDocs.docs.map((document) => {
          const data = document.data();

          var weight = 0;
          if (data.popularity === 0 || data.popularity === undefined) {
            weight = 0;
          } else {
            weight = data.popularity;
          }

          var backgroundImage = "";
          if (data.photo === "" || data.photo === undefined) {
            backgroundImage = Img.blankUserImage;
          } else {
            backgroundImage = data.photo;
          }

          const color = getAcademyColorForId(data.academy) ?? "";

          const session = {
            id: document.id,
            name: data.name,
            academy: data.academy,
            channel: data.channel ?? "",
            logo: data.logo ?? "",
            img: backgroundImage,
            bgColor:
              "linear-gradient(180deg, rgba(45, 144, 141, 0) -8.59%, rgba(6, 141, 137, 0.68) 183.7%)",
            weight: weight,
            color: color,
            // tm1: true
          };
          return session;
        });
        setFeatured(parsedSessions);
      }
    } catch (error) {
      // console.log("Failed: " + error);
    }
  };

  const convertHexToRGBA = (item, opacity) => {
    // Remove the '#' from the beginning of the hex color
    const newHEXColor = item.color;
    const hexColor = newHEXColor.replace("#", "");

    // Extract the RGB values from the hex color
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Create the RGBA string
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const getAcademyNameForId = (targetAcademyId) => {
    if (allAcademies.length > 0) {
      const filtered = allAcademies.filter(
        (academy) => academy.id === targetAcademyId
      );
      if (filtered.length > 0) {
        return filtered[0].name;
      } else {
        return targetAcademyId;
      }
    } else {
      return targetAcademyId;
    }
  };

  const getAcademyLogoForId = (targetAcademyId) => {
    if (allAcademies.length > 0) {
      const filtered = allAcademies.filter(
        (academy) => academy.id === targetAcademyId
      );
      if (filtered.length > 0) {
        return filtered[0].logo;
      } else {
        return targetAcademyId;
      }
    } else {
      return targetAcademyId;
    }
  };

  const getAcademyColorForId = (targetAcademyId) => {
    if (allAcademies.length > 0) {
      const filtered = allAcademies.filter(
        (academy) => academy.id === targetAcademyId
      );
      if (filtered.length > 0) {
        return filtered[0].color;
      } else {
        return overlayColor;
      }
    } else {
      return overlayColor;
    }
  };

  const selectedEducatorSession = (educator) => {
    router.push(`/channel/${educator.academy}/${educator.id}`);
  };

  useEffect(() => {
    fetchAllAcademies();
  }, []);

  return (
    <VStack bgColor="rgba(245, 245, 255, 1)">
      {/* desktop-feature */}
      <Box
        className="desktop-feature"
        w="80%"
        mx="auto"
        bgColor="rgba(255, 255, 255, 1)"
        p="50px 40px"
        borderRadius="26px"
        position="relative"
        top="-66px"
      >
        <Stack>
          <Text
            color="rgba(0, 0, 0, 1)"
            fontSize="30px"
            marginBottom={"25px"}
            fontWeight="700"
          >
            {i18n.t("top_featured_sessions")}
            {/* Top Featured Sessions */}
          </Text>
          {/* Carousel */}
          <Stack>
            <Slider {...settings}>
              {featured.map((item) => (
                <VStack
                  key={item.id}
                  onClick={() => selectedEducatorSession(item)}
                  cursor={"pointer"}
                >
                  <Stack
                    h="auto"
                    boxShadow="0px 0px 11px 0px rgba(0, 0, 0, 0.13)"
                    borderRadius="26px"
                    margin="15px"
                  >
                    <Box
                      w="85%"
                      mx="auto"
                      bgColor={item.bgColor}
                      h={"auto"}
                      position="relative"
                      paddingBottom={"40px"}
                    >
                      <Stack>
                        <Box textAlign="center" pt="30px" pb="18px">
                          <Text
                            noOfLines={1}
                            color="rgba(135, 153, 203, 1)"
                            fontSize="18px"
                            fontWeight="400"
                          >
                            {/* {item.channel} {t('educator')} */}
                            {getAcademyNameForId(item.academy)}{" "}
                            {i18n.t("educator")}
                          </Text>
                          <Text
                            color="rgba(0, 0, 0, 1)"
                            fontSize="20px"
                            fontWeight="700"
                            mt="2"
                          >
                            {item.name}
                          </Text>
                        </Box>
                      </Stack>
                      <Stack>
                        <div className="image-container" borderRadius="20px">
                          <Image
                            src={item.img}
                            alt="logo"
                            w="100%"
                            borderRadius="20px"
                            h="100%"
                            fit={"cover"}
                            objectFit={"cover"}
                          />
                          <div
                            className="image-overlay"
                            borderRadius="25px"
                            style={{
                              background: `linear-gradient(to bottom, rgba(0, 0, 0, 0), ${convertHexToRGBA(
                                item,
                                overlayOpacity
                              )})`,
                            }}
                          ></div>
                          <Image
                            src={getAcademyLogoForId(item.academy)}
                            alt="logo"
                            position={"absolute"}
                            top="20px"
                            left={"20px"}
                            w={"57px"}
                            hidden={getAcademyLogoForId(item.academy) !== undefined ? false : true}
                          />
                        </div>
                      </Stack>
                    </Box>
                  </Stack>
                </VStack>
              ))}
            </Slider>
          </Stack>
        </Stack>
      </Box>

      {/* mobile-feature */}
      <Box
        className="mobile-feature"
        my="50px"
        w="85%"
        mx="auto"
        bgColor="rgba(255, 255, 255, 1)"
        p="50px 20px"
        borderRadius="18px"
      >
        <Stack>
          <Text
            fontSize="24px"
            fontFamily="ProximaNova"
            fontWeight="700"
            textAlign="center"
            py="15px"
            color="rgba(0, 0, 0, 1)"
          >
            {i18n.t("top_featured_sessions")}
          </Text>

          {/* Carousel */}
          <Stack w="100%" h="100%">
            {productSide.map((item) => (
              <VStack
                w="100%"
                onClick={() => selectedEducatorSession(item)}
                cursor={"pointer"}
              >
                <Stack
                  h="100%"
                  boxShadow="0px 0px 11px 0px rgba(0, 0, 0, 0.13)"
                  borderRadius="26px"
                  margin="15px"
                  w="100%"
                >
                  <Box textAlign="center" py="10px">
                    <Text
                      paddingTop={5}
                      fontSize="18px"
                      noOfLines={1}
                      fontWeight="400"
                      color="rgba(135, 153, 203, 1)"
                    >
                      {getAcademyNameForId(item.academy)} {i18n.t("educator")}
                    </Text>
                    <Text
                      color="rgba(0, 0, 0, 1)"
                      fontSize="20px"
                      fontWeight="700"
                      mt="0"
                    >
                      {item.name}
                    </Text>
                  </Box>
                  <Box
                    w="85%"
                    mx="auto"
                    bgColor={item.bgColor}
                    h={"100%"}
                    position="relative"
                    paddingBottom="20px"
                  >
                    <Image
                      src={getAcademyLogoForId(item.academy)}
                      alt="logo"
                      position={"absolute"}
                      top="20px"
                      left={"20px"}
                      w={"57px"}
                    />
                    <Image
                      src={item.img}
                      alt="logo"
                      w="100%"
                      borderRadius="32px"
                      h="100%"
                      objectFit={item.tm1 === true ? "contain" : "cover"}
                      marginBottom={5}
                    />
                  </Box>
                </Stack>
              </VStack>
            ))}
            <Button
              w="60%"
              mx="auto"
              bg="rgba(0, 113, 193, 1)"
              color="#fff"
              fontSize="20px"
              fontFamily="'Roboto', sans-serif"
              fontWeight="400"
              textAlign="center"
              h="49px"
              _hover={{
                color: "#ffff",
              }}
              onClick={() => setSlide(!showSlide)}
            >
              {i18n.t("load_more")}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </VStack>
  );
};

export default Feature;
