import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoutes from './ProtectedRoutes'
import Layout from '../Layout'
import Home from '../views/Home/Home'
import Live from '../views/live'
import NotFound from '../views/NotFound/NotFound'
import Login from '../views/Auth/Login/Login'

import LiveCast from '../views/liveCast'
import FullScreenVideoPlayer from '../views/FullScreenVideoPlayer/FullScreenVideoPlayer'

function Routing() {
    return (
        <div>
            <Routes>
                <Route element={<ProtectedRoutes />}>
                    <Route element={<Layout />}>
                        <Route exact path='/' element={<Home/>} />
                        <Route path='/channel/:academyId/:educatorId?/:timezoneId?' element={<Live />} />
                        <Route path='/strategy/:strategyId?' element={<NotFound />} />
                        <Route exact path='/session/:sessionId?' element={<LiveCast/>} />
                        <Route exact path='/recorded-session/:itemId?' element={<FullScreenVideoPlayer/>} />
                    </Route>
                </Route>
                <Route path='/login' element={<Login />} />
            </Routes>
        </div>
    )
}

export default Routing
