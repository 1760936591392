import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import { firestore } from "../../../util/firebase";
import { deleteDoc, doc, getDocs, setDoc, collection, query, getDoc } from "firebase/firestore";
import { useRouter } from "../../../hooks/use-router";

const BroadCastInfo = (props) => {

  const { educator, session, administrator } = props;

  const router = useRouter();
  const [isNowLive, setIsNowLive] = useState(false);

  const [chatEnabled, setChatEnabled] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [url, setURL] = useState('');
  const [streamKey, setStreamKey] = useState('');
  const [currentChatroomID, setCurrentChatroomID] = useState('');

  const setupAdminFeatures = () => {
    const sessionStatus = session.live ?? false;
    setIsNowLive(sessionStatus);
  };

  const toggleChatroom = async () => {
    if (session.id !== undefined) {
      const sessionsCollection = collection(firestore, 'sessions');
      const sessionDocRef = doc(sessionsCollection, session.id);

      await getDoc(sessionDocRef).then(async (sessionDocQuery) => {
        if (sessionDocQuery.exists() === true) {
          const data = sessionDocQuery.data();
          const educatorId = data.educator_id ?? '';

          var chatroomLive = false;
          if (data.chat_enabled !== undefined) {
            chatroomLive = data.chat_enabled ?? false;
          };

          if (educatorId != '') {
            const educatorDocRef = doc(firestore, 'educators', educatorId);

            await getDoc(educatorDocRef).then(async (educatorSnapshot) => {
              if (educatorSnapshot.exists() === true) {
                const educatorDocumentData = educatorSnapshot.data();
                const chatroomId = educatorDocumentData.chatroom_id ?? '';

                setCurrentChatroomID(chatroomId);

                if (!chatroomLive === true) {
                  enableChatroom();
                } else {
                  disableChatroom();
                };

                await setDoc(sessionDocRef, {chat_enabled: !chatroomLive}, {merge:true}).then(() => {
                  setChatEnabled(!chatroomLive);
                });
              } else {
                // Educator does not exist
                console.log('This educator does not exist');
              }
            }).catch((educatorError) => {
              console.log(educatorError);
            });
          };
        }
      });
    }
  };

  const enableChatroom = async () => {
    try {
      const options = {
        method: 'POST',
        headers: {
          'origin': '*',
          'x-requested-with': '*',
          'Content-Type': 'application/json',
          'jifuapi-auth-key': '31cd9073-bbe0-430e-831b-000e73b1a6c8'
        },
        body: JSON.stringify({
          roomId: currentChatroomID
        })
      };

      const response = await fetch(`https://corsavailable.herokuapp.com/https://jifulive-api-8743a04c5191.herokuapp.com/chat/chatroom/on`, options);

      console.log(response.status);
      if (response.status === 200) {
        window.location.reload();
      } else{
        console.log('Failed to clear chatroom')
      };
    } catch (error) {
      console.error('An error occurred:', error);
    };
  };

  const disableChatroom = async () => {
    try {
      const options = {
        method: 'POST',
        headers: {
          'origin': '*',
          'x-requested-with': '*',
          'Content-Type': 'application/json',
          'jifuapi-auth-key': '31cd9073-bbe0-430e-831b-000e73b1a6c8'
        },
        body: JSON.stringify({
          roomId: currentChatroomID
        })
      };

      const response = await fetch(`https://corsavailable.herokuapp.com/https://jifulive-api-8743a04c5191.herokuapp.com/chat/chatroom/off`, options);

      if (response.status === 200) {
        window.location.reload();
      } else{
        console.log('Failed to clear chatroom')
      };
    } catch (error) {
      console.error('An error occurred:', error);
    };
  };

  const setupStream = async () => {
    if (session.id !== undefined) {
      const sessionsCollection = collection(firestore, 'sessions');
      const sessionDocRef = doc(sessionsCollection, session.id);

      await getDoc(sessionDocRef).then(async (sessionDocQuery) => {
        if (sessionDocQuery.exists() === true) {
          const data = sessionDocQuery.data();

          var chatroomLive = false;
          if (data.chat_enabled !== undefined) {
            chatroomLive = data.chat_enabled ?? false;
          };

          setChatEnabled(chatroomLive);
        }
      });
    }
  };

  const toggleStreamStatus = async () => {
    const sessionId = session.id;
    if (isNowLive === true) {
      setIsNowLive(false);
    } else {
      setIsNowLive(true);
    };

    try {
      const response = await fetch(`https://corsavailable.herokuapp.com/https://jifutv-api-398a7ca23585.herokuapp.com/stream/update-channel`, {
        method: 'POST',
        headers: {
          'origin': '*',
          'x-requested-with': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId: sessionId,
          educatorId: educator.id,
          live: !isNowLive
        }),
      });

      if (response.status === 200) {
        // Request was successful
        if (isNowLive === false) {
          clearChatrooms();
        } else {
          router.refresh();
        };
        
      } else {
        // Handle errors here
        console.error('Request failed');
      };
    } catch (error) {
      console.error('An error occurred:', error);
    };
  };

  const clearChatrooms = async () => {
    const sessionId = session.id;
    const sessionsCollection = collection(firestore, 'sessions');
    const subCollectionRef = collection(doc(sessionsCollection, sessionId), 'Chatroom');

    try {
      await getDocs(subCollectionRef).then((documents) => {
        documents.forEach(async (document) => {
          await deleteDoc(document);
        });
        router.refresh();
      }).catch(() => {
        router.refresh();
      });
    } catch(error) {
      console.log(error);
      router.refresh();
    };
  };

  useEffect(() => {
    setupAdminFeatures();
    setupStream();
  },[]);

  return (
    <VStack
      w="100%"
      pb=""
      pt=""
      borderRadius="26px"
      bg={"rgba(255, 255, 255, 1)"}
    >
      <Stack
        justifyContent={"flex-start"}
        alignItems={"center"}
        flexDir="row"
        w="100%"
        p={"0px 25px"}
        mt="20px"
        flexWrap="wrap"
        flexDirection={"column"}
      >
        <Box width="13px" h="25px" bg="#601FEB" borderRadius="7px" />
        <Text
          fontFamily="ProximaNova"
          fontSize="20px"
          fontWeight="800"
          color="rgba(41, 45, 50, 1)"
          fontF=""
        >
          Stream Status
        </Text>
        <Box
          borderBottom="1px solid rgba(236, 236, 236, 1)"
          w="100%"
          py="10px"
        ></Box>

        <Stack w="100%">
          <Box w="100%" mx="auto" my="0px">
            {/* <FormControl w="100%" position="relative" mb="15px">
              <FormLabel
                position="absolute"
                left="22px"
                top="5px"
                zIndex="22"
                color="#000000"
                fontSize="12px"
                fontFamily="ProximaNova"
              >
                Server
              </FormLabel>
              <Input
                placeholder="Server"
                border={"none"}
                type={showServerKey ? "text" : "password"}
                background="#E6E6E6"
                padding="20px 25px"
                h="65px"
                borderRadius="13px"
                color=" #000000"
                fontSize="14px"
                fontFamily="ProximaNova"
                value={url}
                contentEditable={false}
                _placeholder={{
                  color: " #000000",
                  fontSize: "14px",
                  fontFamily: "ProximaNova",
                }}
                w="100%"
              />
              <Box
                  position={"absolute"}
                  right={"10px"}
                  top={"25px"}
                  color="rgba(77, 77, 77, 1)"
                  fontSize={"16px"}
                  cursor="pointer"
                  onClick={() => setShowServerKey(!showServerKey)}
                >
                  {showServerKey ? <AiFillEyeInvisible /> : <AiFillEye />}
                </Box>
            </FormControl>
            <FormControl w="100%" position="relative" mb="15px">
              <FormLabel
                position="absolute"
                left="22px"
                top="5px"
                zIndex="22"
                color="#000000"
                fontSize="12px"
                fontFamily="ProximaNova"
              >
                Stream Key
              </FormLabel>
              <Input
                placeholder="Stream Key"
                border={"none"}
                type={showStreamKey ? "text" : "password"}
                background="#E6E6E6"
                padding="20px 25px"
                h="65px"
                borderRadius="13px"
                color=" #000000"
                fontSize="14px"
                contentEditable={false}
                fontFamily="ProximaNova"
                value={streamKey}
                _placeholder={{
                  color: " #000000",
                  fontSize: "14px",
                  fontFamily: "ProximaNova",
                }}
                w="100%"
              />
              <Box
                  position={"absolute"}
                  right={"10px"}
                  top={"25px"}
                  color="rgba(77, 77, 77, 1)"
                  fontSize={"16px"}
                  cursor="pointer"
                  onClick={() => setShowStreamKey(!showStreamKey)}
                >
                  {showStreamKey ? <AiFillEyeInvisible /> : <AiFillEye />}
                </Box>
            </FormControl>
            <FormControl w="100%" position="relative" mb="15px">
              <FormLabel
                position="absolute"
                left="22px"
                top="5px"
                zIndex="22"
                color="#000000"
                fontSize="12px"
                fontFamily="ProximaNova"
              >
                Username
              </FormLabel>
              <Input
                placeholder="Username"
                border={"none"}
                type={showUsername ? "text" : "password"}
                background="#E6E6E6"
                padding="20px 25px"
                h="65px"
                borderRadius="13px"
                color=" #000000"
                fontSize="14px"
                contentEditable={false}
                fontFamily="ProximaNova"
                value={username}
                _placeholder={{
                  color: " #000000",
                  fontSize: "14px",
                  fontFamily: "ProximaNova",
                }}
                w="100%"
              />
              <Box
                  position={"absolute"}
                  right={"10px"}
                  top={"25px"}
                  color="rgba(77, 77, 77, 1)"
                  fontSize={"16px"}
                  cursor="pointer"
                  onClick={() => setShowUsername(!showUsername)}
                >
                  {showUsername ? <AiFillEyeInvisible /> : <AiFillEye />}
                </Box>
            </FormControl>
            <FormControl w="100%" position="relative">
              <FormLabel
                position="absolute"
                left="22px"
                top="5px"
                zIndex="22"
                color="#000000"
                fontSize="12px"
                fontFamily="ProximaNova"
              >
                Password
              </FormLabel>
              <Input
                placeholder="Password"
                border={"none"}
                background="#E6E6E6"
                type={showPass ? "text" : "password"}
                padding="20px 25px"
                h="65px"
                borderRadius="13px"
                color=" #000000"
                fontSize="14px"
                contentEditable={false}
                fontFamily="ProximaNova"
                value={password}
                _placeholder={{
                  color: " #000000",
                  fontSize: "14px",
                  fontFamily: "ProximaNova",
                }}
                w="100%"
              />
              <Box
                  position={"absolute"}
                  right={"10px"}
                  top={"25px"}
                  color="rgba(77, 77, 77, 1)"
                  fontSize={"16px"}
                  cursor="pointer"
                  onClick={() => setShowPass(!showPass)}
                >
                  {showPass ? <AiFillEyeInvisible /> : <AiFillEye />}
                </Box>
            </FormControl> */}
            <Button
            onClick={() => toggleStreamStatus()}
            color="#fff" 
            mt="20px"
            mb="0px"
            borderRadius="13px"
            fontSize="15px"
            fontWeight='600'
            fontFamily="ProximaNova" 
            bg={isNowLive === false ? "#0071C1" : "#9F2625"} 
            w="100%"
            h="53px"
            
            >
              {isNowLive === false ? "Start Live Broadcast" : "Stop Live Broadcast"}
            </Button>
            <Button
            onClick={() => toggleChatroom()}
            color="#fff" 
            mt="20px"
            mb="0px"
            borderRadius="13px"
            fontSize="15px"
            fontWeight='600'
            fontFamily="ProximaNova" 
            bg={chatEnabled === false ? "#0071C1" : "#9F2625"} 
            w="100%"
            h="53px"
            
            >
              {chatEnabled === false ? "Enable Chatroom" : "Disable Chatroom"}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </VStack>
  );
};

export default BroadCastInfo;

BroadCastInfo.propTypes = {
  educator: PropTypes.any,
  session: PropTypes.any,
  administrator: PropTypes.bool
};