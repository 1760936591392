import { Box, Button, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { firestore } from "../../../util/firebase";
import { getDoc, collection, doc, onSnapshot, query } from "firebase/firestore";
import PropTypes from 'prop-types';
import { Img } from "../../../content/Content";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useParams } from "react-router-dom";
import { useRouter } from "../../../hooks/use-router";
import i18n from "../../../i18n/config";

const BroadCast = (props) => {

  const { educator, session, administrator, academy } = props;

  const { sessionId } = useParams();
  const router = useRouter();
  const videoRef = useRef(null);
  const playerRef = useRef();

  const [isNowLive, setIsNowLive] = useState(false);
  const [sessionTitle, setSessionTitle] = useState("");
  const [currentPlayer, setCurrentPlayer] = useState();
  const [currentStreamKey, setCurrentStreamKey] = useState('');
  const [currentStreamURL, setCurrentStreamURL] = useState('');
  const [educatorName, setEducatorName] = useState("");
  const [educatorPhoto, setEducatorPhoto] = useState("");

  const setupStream = async () => {
    const name = educator.name;
    const photo = educator.photo;
    const streamKey = educator.server_key;

    const title = session.title;
    const live = session.live ?? false;
    
    setCurrentStreamKey(streamKey);
    setEducatorName(name);
    setEducatorPhoto(photo);

    setIsNowLive(live);
    setSessionTitle(title);

    var userType = '';
    if (administrator === true) {
      userType = 'admin'
    } else {
      userType = 'customer'
    };

    try {
      const options = {
        method: 'POST',
        headers: {
          'origin': '*',
          'x-requested-with': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userType: userType,
          educatorId: educator.id
        }),
      };

      const response = await fetch(`https://corsavailable.herokuapp.com/https://jifutv-api-398a7ca23585.herokuapp.com/stream/channel`, options);

      if (response.status === 200) {

        const responseData = await response.json();

        const iframeCode = responseData.iframe;

        if (iframeCode != undefined) {
          const srcRegex = /src=["'](https?:\/\/[^"']+)["']/;
          const match = iframeCode.match(srcRegex);

          if (match) {
            const srcUrl = match[1];
            setCurrentStreamURL(srcUrl);
          };
        };
      };
    } catch (error) {
      console.error('An error occurred:', error);
    };
  };

  const refreshTapped = () => {
    router.refresh();
  };

  useEffect(() => {
    setupStream();
  },[]);

  return (
    <VStack
      w="100%"
      pb="40px"
      pt="30px"
      borderRadius="26px"
      bg={"rgba(255, 255, 255, 1)"}
      my="50px"
    >
      {/* top bar */}
      <Stack
        flexDir="row"
        justifyContent="space-between"
        w="100%"
        px="20px"
        pb="20px"
      >
        <Box display="flex" alignItems="center">
          <Image src={academy.logo} w="auto" h="40px" mx="10px" />
          <Text color="rgba(149, 151, 171, 1)" fontWeight="500" fontSize="20px">
            {/* FRX102- introductions ti trade overview */}
            {sessionTitle}
          </Text>
        </Box>
      </Stack>

      {/* video Player */}
      <Stack position={"relative"} width={"100%"}>
        {/* <video ref={videoRef} className="video-js" height={'100%'} width={'100%'} /> */}
        {isNowLive === false && (
          <Box height={"500"} width={"100%"} bg={"black"} />
        )}
        {isNowLive === true && currentStreamURL !== '' && (
          <>
            <iframe src={currentStreamURL} style={{ width: "100%", height: '500px', position: "relative", backgroundColor: 'black' }} frameborder="0" allowFullScreen></iframe>
          </>
        )}
        {/* play icon */}
        {isNowLive === false && (
          <Box
            position={"absolute"}
            top={"0"}
            left={"0"}
            right={"0"}
            w="100%"
            height={"100%"}
            display="flex"
            flexDirection="column"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Stack
              flexDir="row"
              justifyContent="space-between"
              w="100%"
              py="40px"
              px="25px"
              h="33%"
            >
              {/* <Image src={Img.FeatureLogo} w="57px" h="49px" mx="10px" /> */}
              <Box
                bg="rgba(255, 255, 255, 0.28)"
                borderRadius="11px"
                p="11px 12px 11px 12px"
                display="flex"
                flexDirection="row"
                justifyContent={"flex-start"}
                alignItems="center"
                me="25px"
              >
                <Image src={educatorPhoto} h="49px" w="49px" objectFit={'cover'} borderRadius={'10px'}/>
                <Text
                  mx="10px"
                  color="rgba(255, 255, 255, 1)"
                  fontSize="20px"
                  fontWeight="600"
                >
                  {educatorName}
                  <Text
                    color="rgba(214, 214, 214, 1)"
                    fontSize="20px"
                    fontWeight="400"
                  >
                    {academy.title != undefined ? academy.title : 'JIFU'} {i18n.t('educator')}
                  </Text>
                </Text>
              </Box>
            </Stack>
            <Stack
              flexDir="row"
              justifyContent="space-between"
              w="100%"
              py="40px"
              px="25px"
              h="33%"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={"center"}
                alignItems="center"
                w="100%"
              >
                {/* <Button>
                <Image src={Img.play} h="81px" w="81px" />
              </Button> */}
              </Box>
            </Stack>

            <Stack
              flexDir="row"
              justifyContent="space-between"
              alignItems={"center"}
              w="100%"
              py="40px"
              px="25px"
              h="33%"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={"flex-start"}
                alignItems="center"
                me="25px"
              >
                <Button
                  bg="rgba(255, 255, 255, 0.28)"
                  borderRadius="11px"
                  p="11px 12px 11px 12px"
                  display="flex"
                  flexDirection="row"
                  justifyContent={"flex-start"}
                  alignItems="center"
                >
                  {/* <Image src={Img.record} h="19px" w="19px" /> */}
                  <Text
                    mx="10px"
                    color="rgba(255, 255, 255, 1)"
                    fontSize="20px"
                    fontWeight="400"
                  >
                    Not Live
                  </Text>
                </Button>
                <Button
                  onClick={() => refreshTapped()}
                  bg="rgba(255, 255, 255, 0.28)"
                  borderRadius="11px"
                  w="50px"
                  h="50px"
                  display="flex"
                  flexDirection="row"
                  justifyContent={"center"}
                  alignItems="center"
                  marginX="10px"
                  p={"10px"}
                >
                  <Image src={Img.refresh} h="20px" w="20px" />
                </Button>
              </Box>
              <Box
                bg="rgba(255, 255, 255, 0.28)"
                borderRadius="5px"
                display="flex"
                flexDirection="row"
                justifyContent={"flex-start"}
                alignItems="center"
                me="25px"
                h="37px"
                px="10px"
              >
                <Text color="rgba(0, 0, 0, 1)" fontSize="14px" fontWeight="400">
                  Live channel might be delayed / refresh the page
                </Text>
              </Box>
            </Stack>
          </Box>
        )}
      </Stack>
    </VStack>
  );
};

export default BroadCast;

BroadCast.propTypes = {
  educator: PropTypes.any,
  session: PropTypes.any,
  administrator: PropTypes.bool,
  academy: PropTypes.any
};
