import {
  Box,
  Container,
  Fade,
  FormControl,
  Button,
  Image,
  Input,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import "./Header.component.css";
import React, { useEffect, useState } from "react";
import { Img } from "../../../content/Content";
import { languages } from "../../../util/language";
import { Link } from "react-router-dom";
import { useRouter } from "../../../hooks/use-router";

function Header() {

  const router = useRouter();
  // const [searchOpen, setSearch] = useState(false);
  const [langDrop, setLangDrop] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const [profile, setProfile] = useState(false);

  const checkForLanguageSelector = () => {
    setIsHome(true);
  };

  const navigateHome = () => {
    window.location.href = ("https://jifulivetv.com/");
  };

  const navigateToProfile = () => {
    console.log('Profile');
  };

  const openNotifications = () => {
    console.log('Notifications');
  };

  const performLogout = () => {
    localStorage.removeItem("user");
    router.replace("/login");
  };

  useEffect(() => {
    checkForLanguageSelector();
  },[]);

  return (
    <VStack bg="rgba(23, 25, 25, 1)" h="94px" position={'fixed'} top={'0'} left={'0'} right={'0'} zIndex="55" flexDirection="row">
      {/* Header */}
          {/* Logo */}
          <Stack paddingLeft={10}>
            <Link onClick={() => navigateHome()}>
              <Box display="flex" >
                <Image src={Img.logoWhite} alt="logo" />
                <Text
                  color={"rgba(0, 113, 193, 1)"}
                  fontSize={"14px"}
                  fontWeight={"400"}
                  position="relative"
                  top="-20px"
                >
                  TV
                </Text>
              </Box>
            </Link>
          </Stack>

          {/* menu bar */}
          <Stack w="80%"  >
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
            >
              {/* language bar */}
              {isHome === false &&
                <Stack >
                <Box marginTop={'10px'} display="flex"  cursor="pointer" justifyContent='center' onClick={()=>setLangDrop(!langDrop)}>
                  <Image
                    src={Img.us}
                    alt="photo"
                    w="28px"
                    h="28px"
                    borderRadius="25"
                  />
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    margin="0 6px"
                    color="#fff"
                  >
                    English
                  </Text>
                </Box>
                <Fade in={langDrop} >
                <Box  h="auto" top='44px'  position="absolute" left={'0'} borderRadius='15px' bg='white'>
                  <UnorderedList padding='0' margin='0' listStyleType="none" display='flex' alignItems='flex-start' justifyContent={'center'} flexDir='column'>
                    {languages.map((items) => {
                      return (
                        <ListItem padding='10px 20px' margin={'5px 0'} boxShadow='lg'>
                          <Box
                            display="flex"
                            alignItems="center"
                            cursor="pointer"
                          >
                            <Image
                              src={items.flag}
                              alt="photo"
                              w="28px"
                              h="28px"
                              borderRadius="25"
                            />
                            <Text
                              fontSize="14px"
                              fontWeight="400"
                              margin="0 6px"
                              color="#000"
                            >
                              {items.name}
                            </Text>
                          </Box>
                        </ListItem>
                      );
                    })}
                  </UnorderedList>
                </Box>
                </Fade>
              </Stack>
              }
              {/* Notification */}
              <Stack>
                <Link onClick={() => openNotifications()}>
                  <Box padding={'0 8px'}>
                    <Image src={Img.notification} />
                  </Box>
                </Link>
              </Stack>
              <Stack>
                <Box padding={'0 8px'} onClick={() => setProfile(!profile)} cursor={'pointer'} >
                  <Image src={Img.profile} />
                </Box>
              </Stack>
              {profile ? (
                  <Box
                    position="absolute"
                    top="70px"
                    borderRadius="10px"
                    bg={"#EDF2F7"}
                    py="15px"
                    width={'150px'}
                  >
                    {/* <Button height={'30px'} w="100%">Profile</Button> */}
                    {/* <Button height={'30px'} w="100%">Setting</Button> */}
                    <Button onClick={() => performLogout()} height={'30px'} w="100%">Logout</Button>
                  </Box>
              ) : (
                ""
              )}
            </Box>
          </Stack>
        </VStack>
  );
}

export default Header;
