import {
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import VideoCarousel from "../components/VideoCarrousel";
import PropTypes from "prop-types";
import { helperMethods } from "./helperMethods";
import { services } from "./services";
import { useCallback } from "react";
import { addDoc, and, collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../util/firebase";
import { Text } from '@chakra-ui/react'
import { Img } from "../../../content/Content";

const RecordedSessions = (props) => {
  const { educator, administrator } = props;
  const [recordedSession, setRecordedSessions] = useState([]);
  const [filteredRecordedSession, setFilteredRecordedSessions] = useState([]);
  const [searchInputText, setSearchInputText] = useState("");
  const [favoriteVideos, setFavoriteVideos] = useState([]);
  const [educatorFavoriteVideos, setEducatorFavoriteVideos] = useState([])

  useEffect(() => {
    setEducatorFavoriteVideos(recordedSession
      .filter(video => video.isFavorite))
  }, [recordedSession])

  const getFavoriteVideosReference = async (videoId) => {
    const videoLikedref = collection(firestore, "favorite-educator-videos");
    const videoLikedExist = query(videoLikedref, and(where("videoId", "==", videoId), where("educatorId", "==", educator.id)))
    return getDocs(videoLikedExist)
  }

  const addToFavorite = async (videoId, unFavoriteCallback) => {
    const currentTimestamp = Math.floor(Date.now() / 1000);

    const liked = await checkIfVideoIsLiked(videoId)
    if (!liked) {
      await addDoc(collection(firestore, "favorite-educator-videos"), {
        videoId,
        educatorId: educator.id,
        currentTimestamp
      }).then(() => {
        const newRecordedSession = [...recordedSession]
        const currentVideo = newRecordedSession.find(v => v.id === videoId)
        currentVideo.isFavorite = true
        setRecordedSessions(newRecordedSession)
      }).catch(err => {
        console.log("add like failed", err)
      });
    } else if (liked) {
      unFavorite(videoId)
      unFavoriteCallback()
    }
  };

  const checkIfVideoIsLiked = async (videoId) => {
    const querySnapshot = await getFavoriteVideosReference(videoId);
    return (querySnapshot.size > 0)
  }
  const unFavorite = async (videoId) => {
    const querySnapshot = await getFavoriteVideosReference(videoId);

    querySnapshot.forEach((document) => {
      deleteDoc(doc(firestore, "favorite-educator-videos", document.ref.id))
    })

    const newRecordedSession = [...recordedSession]
    const currentVideo = newRecordedSession.find(v => v.id === videoId)
    currentVideo.isFavorite = false
    setRecordedSessions(newRecordedSession)
  }
  const getEducatorLikedVideos = async () => {
    const fetchedFavoritesVideos = []
    const videoLikedref = collection(firestore, "favorite-educator-videos");

    const favoriteVideosQuery = query(videoLikedref, where("educatorId", "==", educator.id));

    const querySnapshot = await getDocs(favoriteVideosQuery);

    querySnapshot.forEach((doc) => {
      fetchedFavoritesVideos.push(doc.data());
    });

    setFavoriteVideos(fetchedFavoritesVideos)
  }

  const fetchPreviousStreams = useCallback(async () => {
    if (educator === undefined) {
      return;
    }

    const recordedSessionCollectionRef = collection(firestore, "recorded_sessions");
    const recordedSessionsQuery = query(recordedSessionCollectionRef, where("educator", "==", educator.id));

    getDocs(recordedSessionsQuery).then((recordedSessionDocs) => {
      if (recordedSessionDocs.empty === false) {
        const docs = recordedSessionDocs.docs;
        const paredVideos = docs.map((snapshot) => {
          const video = snapshot.data();
          const recordedSession = {
            id: snapshot.id,
            name: video.title,
            time: video.timestamp,
            duration: video.duration ?? 0,
            timestamp: video.timestamp,
            img: video.thumbnail === '' ? Img.placeholderCoverImage : video.thumbnail,
            bgColor: "rgba(247, 255, 250, 1)",
            duration: 0,
            url: video.url,
            isFavorite: video.favorited ?? false
          };
          return recordedSession;
        });
        setRecordedSessions([...paredVideos.sort(helperMethods.compare)]);
      }
    });
  }, [educator]);


  useEffect(() => {
    getEducatorLikedVideos()
    fetchPreviousStreams();
  }, [fetchPreviousStreams]);

  const onChangeInputSearch = (e) => {
    const newValue = e.target.value
    setSearchInputText(newValue);
    const filterResult = helperMethods.searchByName(
      newValue,
      recordedSession
    );
    setFilteredRecordedSessions([...filterResult]);
  };

  return (

    <Tabs>
      <TabList>
        <Tab >Recorded Sessions</Tab>
        <Tab>Favorite Sessions</Tab>
      </TabList>

      <TabPanels>
        <TabPanel style={{ background: "white" }} height={"30%"}>
        {!recordedSession.length && <Text color='black' justifyContent="center" fontSize='2xl'> No videos available</Text>}
          {Boolean(recordedSession.length) && (
            <>
              <div>
                <Input
                  type="text"
                  bg="transparent"
                  border={"none"}
                  borderRadius="10px"
                  padding={"8px 40px"}
                  placeholder="Search videos"
                  fontFamily="'Roboto', sans-serif"
                  fontSize="20px"
                  fontWeight="500"
                  color="rgba(149, 151, 171, 1)"
                  value={searchInputText}
                  onChange={onChangeInputSearch}
                  _placeholder={{
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "rgba(149, 151, 171, 1)",
                  }}
                  backgroundColor="rgba(255, 255, 255, 1)"
                  _focus={{ outline: "0" }}
                  boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.08)"
                />
              </div>

              <VideoCarousel
                educator={educator}
                administrator={administrator}
                videos={searchInputText ? filteredRecordedSession : recordedSession}
                checkIfVideoIsLiked={checkIfVideoIsLiked}
                unFavorite={unFavorite}
                addToFavorite={addToFavorite}
              />
            </>
          )}
        </TabPanel>
        <TabPanel style={{ background: "white" }}>
        {!educatorFavoriteVideos.length && <Text color='black' justifyContent="center" fontSize='2xl'> No videos available</Text>}
          <Stack height={"auto"}>
            {educatorFavoriteVideos && (
              <VideoCarousel
                educator={educator}
                administrator={administrator}
                videos={educatorFavoriteVideos}
                checkIfVideoIsLiked={checkIfVideoIsLiked}
                unFavorite={unFavorite}
                addToFavorite={addToFavorite}
              />)}
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default RecordedSessions;

RecordedSessions.propTypes = {
  educator: PropTypes.any,
  academy: PropTypes.any,
  administrator: PropTypes.any,
};
