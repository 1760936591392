import { Box, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Img } from "../../../content/Content";
import i18n from "../../../i18n/config";

const FavoriteSessions = () => {

  const [sessions, setSessions] = useState([]);

  const fetchAllSessions = () => {
    // const demoSessions = [{id: 'id1', title: 'Test Session 1 Overview'}]
    // setSessions(demoSessions);
  };

  const selectSession = (session) => {
    // console.log(session.id);
  };

  useEffect(() => {
    fetchAllSessions();
  },[]);

  return (
    <VStack
      w="100%"
      pb="40px"
      borderRadius="26px"
      bg={"rgba(255, 255, 255, 1)"}
      mx="30px"
      h="100%"
    >
      {/* Top Bar */}
      <Stack
        justifyContent={"flex-start"}
        alignItems={"center"}
        flexDir="row"
        w="100%"
        p={"15px 25px"}
        mt="40px"
        flexWrap="wrap"
        flexDirection={"column"}
      >
        <Image src={Img.favorite} width="13px" h="25px" />
        <Text
          fontFamily="ProximaNova"
          fontSize="20px"
          fontWeight="800"
          color="rgba(41, 45, 50, 1)"
        >
          {i18n.t('favorite_sessions')}
        </Text>
        <Box
          borderBottom="1px solid rgba(236, 236, 236, 1)"
          w="100%"
          py="10px"
        ></Box>
      </Stack>
      <Stack p={"15px 25px"} w="100%">
        {sessions && sessions.map((session) => 
        <>
          <Box cursor={'pointer'} onClick={() => selectSession(session)} bg="rgba(255, 255, 255, 1)" boxShadow= "0px 10px 10px 0px rgba(96, 31, 235, 0.06)" padding={'15px 24px'} w="100%" display="flex" justifyContent="flex-start" alignItems='center' borderRadius="15px">
            <Image src={Img.play} w="21.67px" h="26px"/>
            <Text           
              fontFamily="ProximaNova"
              fontSize="20px"
              fontWeight="800"
              color="rgba(149, 151, 171, 1)"
              mx="10px">
                {session.title}
              {/* FRX102- introductions ti trade  <br />
              overview */}
            </Text>
          </Box>
        </>
        )}
      </Stack>
    </VStack>
  );
};

export default FavoriteSessions;
