import {
  Box,
  FormControl,
  Image,
  Input,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Img } from "../../../../content/Content";
import { useParams } from "react-router-dom";
// import { AiOutlineSearch } from "react-icons/ai";
import "./searching.component.css";
import { firestore } from "../../../../util/firebase";
import { getDoc, doc } from "firebase/firestore";

const Searching = () => {

  const { academyId } = useParams(); // educatorId
  const [academyLogo, setAcademyLogo] = useState(null);
  const [academyDescription, setAcademyDescription] = useState(null);
  const [overlayColor, setOverlayColor] = useState('#0071C1'); // Default color
  const [bannerImage, setBannerImage] = useState(Img.liveSearchBg);
  const overlayOpacity = 0.6;

  const fetchAcademy = async () => {
    const academyDocRef = doc(firestore, 'academy', academyId);
    try {
      const academyDocRefSnap = await getDoc(academyDocRef);
      if (academyDocRefSnap.exists() === true) {
        const academyDocData = academyDocRefSnap.data();
        const description = academyDocData.description ?? '';
        const academyColor = academyDocData.color ?? "#0071C1";
        const banner = academyDocData.main_banner ?? '';
        if (banner !== '' && banner !== undefined) {
            setBannerImage(banner);
        };

        if (academyDocData.logo_white === undefined || academyDocData.logo_white === '') {
          const logo = academyDocData.logo ?? '';
          setAcademyLogo(logo);
        } else {
          const logo = academyDocData.logo_white ?? '';
          setAcademyLogo(logo);
        };
        setOverlayColor(academyColor);
        setAcademyDescription(description)
      }
    } catch {
      //
    };
  };

  const convertHexToRGBA = (hexColor, opacity) => {
    // Remove the '#' from the beginning of the hex color
    hexColor = hexColor.replace('#', '');
  
    // Extract the RGB values from the hex color
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);
  
    // Create the RGBA string
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  useEffect(() => {
    fetchAcademy();
  },[]);

  return (
    <VStack position={"relative"} py="25px" w="100%">
      <div className="image-container" borderRadius="25px">
        <Image
          src={bannerImage}
          alt=""
          w="100%"
          height="auto"
          borderRadius="25px"
        />
        {/* <div className="image-overlay" borderRadius="25px" style={{ background: `linear-gradient(to right, rgba(0, 0, 0, 0), ${convertHexToRGBA(overlayColor, overlayOpacity)})` }}></div> */}
      </div>
      {/* <Image
        src={Img.liveSearchBg}
        alt=""
        w="100%"
        height="100%"
        borderRadius="25px"
      /> */}
      <Stack
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        flexDirection="row"
        alignItems="center"
        justifyContent={"flex-start"}
      >
        {/* <Box
          w="50%"
          p="30px"
          display="flex"
          justifyContent="flex-start"
          flexDirection={"column"}
        >
          <Image
            src={academyLogo}
            alt="liveLogo"
            w="153px"
            height="auto"
          />
          <Text
            fontFamily="'Roboto', sans-serif"
            color="#fff"
            fontSize="20px"
            fontWeight="500"
          >
            {academyDescription}
          </Text>
        </Box> */}
        {/* <Box
          w="50%"
          p="30px"
          display="flex"
          alignItems="flex-end"
          flexDirection={"column"}
        >
          <Stack>
            <FormControl
              position={"relative"}
              display="inline-block"
              alignItems="center"
              margin="0 10px"
            >
              <Input
                type="text"
                bg="transparent"
                border={"none"}
                borderRadius="10px"
                padding={"8px 30px"}
                placeholder="Search educators"
                fontFamily="'Roboto', sans-serif"
                fontSize="20px"
                fontWeight="500"
                color="rgba(149, 151, 171, 1)"
                _placeholder={{ fontFamily:"'Roboto', sans-serif",
                fontSize:"15px",
                fontWeight:"500",
                color:"rgba(149, 151, 171, 1)"}}
                backgroundColor ="rgba(255, 255, 255, 1)"
                _focus={{outline:'0'}}
              />
              <Image
                src={Img.search}
                alt="search"
                position={"absolute"}
                right="14px"
                top="14px"
                cursor="pointer"
                height="19px"
                w="19px"
              />
            </FormControl>
          </Stack>
        </Box> */}
      </Stack>
    </VStack>
  );
};

export default Searching;
