import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/layouts/Header/Header";
import HeaderGap from "./components/layouts/Header/HeaderGap";
import { useState } from "react";

const Layout = () => {
  const [status, setStatus] = useState(false);
  return (
    <>
    <Header/>
    <HeaderGap/>
    <Outlet />
    </>
  );
};

export default Layout;