import { VStack } from '@chakra-ui/react';
import React from 'react';

const HeaderGap = () => {
    return (
        <VStack h="94px">
            
        </VStack>
    );
};

export default HeaderGap;