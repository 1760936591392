import { Container, VStack, Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Searching from './components/Searching/Searching';
import Filtering from './components/Searching/Filtering';
import Calender from './components/Searching/Calender';
import Educators from './components/Searching/educators';
import { useRouter } from "../../../src/hooks/use-router";
import { useLocation, useParams } from 'react-router-dom';
import './live.component.css'

const Live = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    },[pathname]);

    return (
        <VStack bg={"rgba(245, 245, 255, 1)"}>
                <Box w="80%" mx="auto" className='live-area'>
                    <Searching/>
                    <Filtering/>
                    <Calender/>
                    <Educators/>
                </Box>
        </VStack>
    );
};

export default Live;