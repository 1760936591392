import { Box, Container, Fade, Image, ListItem, SliderTrack, Stack, Text, UnorderedList, VStack, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { languages } from "../../../../util/language";
import { useRouter } from "../../../../hooks/use-router";
import { useParams } from "react-router-dom";
import i18n from "../../../../i18n/config";

const Filtering = () => {

  const router = useRouter();
  const { academyId, educatorId, timezoneId } = useParams();
  const [langDrop, setLangDrop] = useState(false);

  const timezones = [
    {id: 'EST', title: 'Eastern Standard Time'}, 
    {id: 'CST', title: 'Central Standard Time'}, 
    {id: 'MST', title: 'Mountain Standard Time'},
    {id: 'PST', title: 'Pacific Standard Time'}
  ]

  const removeFiltering = () => {
    router.replace(`/channel/${academyId}`);
  };

  const selectedNewTimezone = (timezone) => {
    router.replace(`/channel/${academyId}/${educatorId}/${timezone.id}`);
    setLangDrop(!langDrop)
  };

  const getCurrentTimezone = () => {
    if (timezoneId != undefined && timezoneId != '') {
      const currentTimezones = timezones.filter((timezone) => timezone.id === timezoneId);
      if (currentTimezones.length > 0) {
        return currentTimezones[0].title;
      } else {
        return i18n.t('select_timezone');
      };
    } else {
      return i18n.t('select_timezone');
    };
  }

  useEffect(() => {
    console.log(timezoneId);
  },[]);

  return (
    <VStack w="100%" flexDirection="row">
      <Box
        gap="0"
        w="50%"
        display="flex"
        flexDirection={"row"}
        alignItems="center"
        justifyContent="flex-start"
      >
        {educatorId !== undefined && 
        <Button
          onClick={() => removeFiltering()}
          fontFamily="'Roboto', sans-serif"
          fontSize="18px"
          fontWeight="500"
          bg="rgba(96, 31, 235, 1)"
          borderRadius="10px"
          padding="12px 30px"
          color="rgba(255, 255, 255, 1)"
          boxShadow={"0px 15px 17px 0px rgba(96, 31, 235, 0.17)"}
        >
          {i18n.t('all_sessions')}
        </Button>
        }

        {/* <Button
          fontFamily="'Roboto', sans-serif"
          fontSize="18px"
          fontWeight="500"
          bg="rgba(255, 255, 255, 1)"
          marginX="20px"
          borderRadius="10px"
          padding="12px 30px"
          transition="0.3s"
          color="rgba(0, 0, 0, 1)"
          boxShadow={"0px 15px 17px 0px rgba(96, 31, 235, 0.17)"}
          _hover={{
            backgroundColor: "rgba(96, 31, 235, 1)",
            color: "rgba(255, 255, 255, 1)",
          }}
        >
          FRX Academic
        </Button> */}

        {/* <Button
          fontFamily="'Roboto', sans-serif"
          fontSize="18px"
          fontWeight="500"
          bg="rgba(255, 255, 255, 1)"
          marginX="20px"
          borderRadius="10px"
          padding="12px 30px"
          transition="0.3s"
          color="rgba(0, 0, 0, 1)"
          boxShadow={"0px 15px 17px 0px rgba(96, 31, 235, 0.17)"}
          _hover={{
            backgroundColor: "rgba(96, 31, 235, 1)",
            color: "rgba(255, 255, 255, 1)",
          }}
        >
          FRX Pro
        </Button> */}
      </Box>
      <Box
        gap="0"
        w="50%"
        display="flex"
        flexDirection={"row"}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box  w="100%" justifyContent='end' display='flex'>
          
          
          <Stack position="relative" w='250px' mx='5px'>
            <Button
              borderRadius="10px"
              padding="12px 30px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bg={"rgba(255, 255, 255, 1)"}
              boxShadow={"0px 0px 4px 0px rgba(0, 0, 0, 0.08)"}
              fontFamily="'Roboto', sans-serif"
              fontSize="15px"
              fontWeight="500"
              transition="0.3s"
              color="rgba(0, 0, 0, 1)"
              w="100%"
              onClick={() => setLangDrop(!langDrop)}
            >
              {getCurrentTimezone()} <FaAngleDown style={{marginLeft:'15px',marginTop:'5px'}} />
            </Button>
            <Fade in={langDrop} >
                  <Box  w="100%" h="auto" top='57px'  position="absolute" right={'0'} borderRadius='15px' bg='white' zIndex={'1111'} boxShadow='lg'>
                    <UnorderedList padding='0' margin='0' listStyleType="none" display='flex' alignItems='flex-start' justifyContent={'center'} flexDir='column'>
                      {timezones.map((items) => {
                        return (
                          <ListItem key={items.id} onClick={() => selectedNewTimezone(items)} padding='10px 20px' margin={'5px 0'} >
                            <Box
                              display="flex"
                              alignItems="center"
                              cursor="pointer"
                            >
                              <Text
                                fontSize="15px"
                                fontWeight="400"
                                margin="0 6px"
                                color="#000"
                              >
                                {items.title}
                              </Text>
                            </Box>
                          </ListItem>
                        );
                      })}
                    </UnorderedList>
                  </Box>
            </Fade>
          </Stack>
        </Box>
      </Box>
    </VStack>
  );
};

export default Filtering;
