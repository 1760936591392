
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { currentLanguage } from '../util/language';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: currentLanguage().id ?? 'en',
  resources: {
    en: {
        translations: require('./locales/en/translations.json')
    },
    es: {
        translations: require('./locales/es/translations.json')
    },
    fr: {
        translations: require('./locales/fr/translations.json')
    },
    de: {
        translations: require('./locales/de/translations.json')
    },
    ru: {
        translations: require('./locales/ru/translations.json')
    },
    it: {
        translations: require('./locales/it/translations.json')
    }
  },
  load: 'languageOnly',
  saveMissing: true,
  ns: ['translations'],
  defaultNS: 'translations',
  debug: true,
  react: {
    wait: false
  }
});

i18n.languages = ['en', 'es', 'fr', 'de', 'ru', 'it'];

export default i18n;