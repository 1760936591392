import {  Box, Container,  Stack,  VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { firestore } from "../../util/firebase";
import BroadCast from './components/BroadCast';
import RecordedSessions from './RecordedSessions/RecordedSessions';
import LiveChat from './components/LiveChat';
import FavoriteSessions from './components/FavoriteSessions';
import { useLocation, useParams } from 'react-router-dom';
import { getDoc, doc } from "firebase/firestore";
import Header from "../../components/layouts/Header/Header";
import HeaderGap from "../../components/layouts/Header/HeaderGap";
import "./liveCast.component.css";

const LiveCast = () => {
    const { pathname } = useLocation();
    const { sessionId } = useParams();

    const [educator, setEducator] = useState();
    const [academy, setAcademy] = useState();
    const [session, setSession] = useState();
    const [admin, setAdmin] = useState();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Event listener to update window width on resize
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    const checkForMemberEducator = async () => {
        const sessionDocRef = doc(firestore, "sessions", sessionId);
        await getDoc(sessionDocRef).then(async (sessionSnapshot) => {
            if (sessionSnapshot.exists() === true) {
                const sessionData = sessionSnapshot.data();
                const educatorId = sessionData.educator_id ?? '';
                const academyId = sessionData.academy_id ?? '';

                setSession(sessionData);

                const academyDocRef = doc(firestore, "academy", academyId);
                await getDoc(academyDocRef).then(async (academySnapshot) => {
                    if (academySnapshot.exists() === true) {
                        const academyData = academySnapshot.data();
                        
                        setAcademy(academyData);

                        const educatorDocRef = doc(firestore, "educators", educatorId);
                        await getDoc(educatorDocRef).then((educatorSnapshot) => {
                            if (educatorSnapshot.exists() === true) {
                                const educatorData = educatorSnapshot.data();
                                educatorData.id = educatorId;

                                const memberId = educatorData.member_id ?? '';
                                setEducator(educatorData);

                                if (localStorage.getItem('user') !== undefined) {
                                    const currentUser = JSON.parse(localStorage.getItem('user'));
                                    console.log(currentUser);
                                    if (memberId === currentUser.id ?? '') {
                                        // TODO: Show administrator views
                                        setAdmin(true);
                                    } else {
                                        // MARK: Hide administrator views
                                        setAdmin(false); // TODO: CHANGE TO FALSE
                                    };
                                };
                            } else {
                                // Doesn't exist
                            };
                        }).catch((educatorError) => {
                            console.log(educatorError);
                            // Doesn't exist
                        });
                    } else {
                        // Doesn't exist
                    }
                }).catch((academyError) => {
                    console.log(academyError);
                });
            } else {
                // Doesn't exist
            };
        }).catch((error) => {
            console.log(error);
            // Doesn't exist
        });
    };

    useEffect(() => {
        checkForMemberEducator();
        window.scrollTo(0, 0);
    },[pathname]);


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[]);

    return (
        <div>
        {/* <Header />
        <HeaderGap /> */}
        <VStack
            bg={"rgba(245, 245, 255, 1)"}
            w="100%"
            h="100%"
            paddingBottom="25px"
        >
            <Box w="80%" mx="auto" className="live-cast">
            {/* desktop-version */}
            {windowWidth > 768 && 
            <>
                <Stack
                    flexDir="row"
                    gap={"0"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    w="100%"
                    className="desktop-version"
                >
                    {admin !== undefined && educator !== undefined &&
                        <>
                        <Box w="70%" h="100%">
                            <BroadCast
                            educator={educator}
                            session={session}
                            administrator={admin}
                            academy={academy}/>
                            <RecordedSessions
                            administrator={admin}
                            educator={educator}
                            academy={academy}
                            />
                        </Box>
                        <Box w="30%" h="100%">
                            <LiveChat
                            educator={educator}
                            session={session}
                            administrator={admin}/>
                            <FavoriteSessions/>
                        </Box>
                        </>
                    }
                </Stack>
            </>
            }
            {/* mobile-version - 768px*/}
            {window.innerWidth < 768 &&
            <>
                <Stack
                    gap={"0"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    w="100%"
                    className="mobile-version"
                >
                    {admin !== undefined && educator !== undefined &&
                        <>
                        <Box w="100%" h="100%">
                            <BroadCast
                            educator={educator}
                            session={session}
                            administrator={admin}
                            academy={academy}/>
                            <LiveChat
                            educator={educator}
                            session={session}
                            administrator={admin}/>
                        </Box>
                        <Box w="100%" h="100%">
                            <RecordedSessions
                            administrator={admin} 
                            />
                            <FavoriteSessions />
                        </Box>
                        </>
                    }
                </Stack>
            </>
            }
            </Box>
        </VStack>
        </div>
    )
    // return (
    //     <VStack bg={"rgba(245, 245, 255, 1)"} w="100%" h="100%" paddingBottom="25px">
    //         <Container w="80%" mx="auto">

    //             <Stack flexDir='row'gap={'0'} justifyContent='flex-start' alignItems='flex-start' w="100%">
    //                 {admin !== undefined && educator !== undefined &&
    //                     <Box w="70%" h="100%">
    //                         <BroadCast
    //                         educator={educator}
    //                         session={session}
    //                         administrator={admin}
    //                         academy={academy}
    //                         />
    //                         <RecordedSessions/>
    //                     </Box>
    //                 }
    //                 {admin !== undefined && educator !== undefined &&
    //                     <Box w="30%" h="100%">
    //                         <LiveChat
    //                             educator={educator}
    //                             session={session}
    //                             administrator={admin}
    //                         />
    //                         <FavoriteSessions/>
    //                     </Box>
    //                 }
    //             </Stack>
    //         </Container>
    //     </VStack>
    // );
};

export default LiveCast;