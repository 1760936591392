

  function compare( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }
  
  function searchByName(searchTerm, objects) {
    searchTerm = searchTerm.toLowerCase(); // Convert the search term to lowercase for case-insensitive search
    return objects.filter((obj) => {
      const objName = obj.name.toLowerCase(); // Convert the "name" property value to lowercase
      return objName.includes(searchTerm);
    });
  }

  export const helperMethods = {
    searchByName,
    compare
  }