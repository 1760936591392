import {
  Box,
  Container,
  Fade,
  Image,
  ListItem,
  SliderTrack,
  Stack,
  Text,
  UnorderedList,
  VStack,
  Link,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Img } from "../../../../content/Content";
import { languages, currentLanguage } from "../../../../util/language";
import { useRouter } from "../../../../hooks/use-router";
import i18n from "../../../../i18n/config";
import "./Banner.component.css";

const Banner = () => {
  const router = useRouter();
  // const { t } = ;
  const [langDrop, setLangDrop] = useState(false);
  const [newCurrentLanguage, setNewCurrentLanguage] = useState(null);

  const selectNewLanguage = (language) => {
    localStorage.setItem("CurrentLanguage", language.id);
    setNewCurrentLanguage(language);
    router.refresh();
  };

  const fetchCurrentLanguage = () => {
    setNewCurrentLanguage(currentLanguage());
  };

  useEffect(() => {
    fetchCurrentLanguage();
  }, []);

  return (
    <VStack
      alignItems={"center"}
      justifyContent={"center"}
      backgroundImage={Img.BannerBg}
      bgSize={"cover"}
      bgRepeat="no-repeat"
      height={"500px"}
      className="bg_banner"
    >
      {/* desktop */}
      <Box w="80%" mx={"auto"} className="desktop-banner">
        <Box>
          <Text
            fontWeight="800"
            fontSize="70px"
            lineHeight="1.1"
            color="#fff"
            paddingBottom="0"
            marginBottom="0"
          >
            {i18n.t("welcome_banner")} <br /> JIFU
            <span style={{ color: "rgba(0, 113, 193, 1)" }}> LIVE TV</span>
          </Text>
        </Box>
        <Box display="flex" alignItems="center">
          <Text marginRight={"15px"} color="#fff">
            {i18n.t("choose_language")}
          </Text>
          <Stack position="relative" w="115px" mx="5px">
            {newCurrentLanguage && (
              <Box
                bg={"rgba(240, 240, 250, 1)"}
                marginTop={"10px"}
                display="flex"
                alignItems="center"
                cursor="pointer"
                onClick={() => setLangDrop(!langDrop)}
                borderRadius="20px"
                padding={"8px 35px 8px 10px"}
                width={"150px"}
              >
                <Image
                  src={newCurrentLanguage.flag}
                  alt="photo"
                  w="28px"
                  h="28px"
                  borderRadius="25"
                  marginRight={"8px"}
                />
                <Text
                  fontSize="20px"
                  fontWeight="400"
                  color="#rgba(0, 0, 0, 1)"
                  padding={"0"}
                  margin={"0"}
                >
                  {newCurrentLanguage.name}
                </Text>
              </Box>
            )}
            <Fade in={langDrop}>
              <Box
                w="146px"
                h="auto"
                top="57px"
                position="absolute"
                left={"0"}
                borderRadius="15px"
                bg="white"
                zIndex={"1111"}
                boxShadow="lg"
              >
                <UnorderedList
                  padding="0"
                  margin="0"
                  listStyleType="none"
                  display="flex"
                  alignItems="flex-start"
                  justifyContent={"center"}
                  flexDir="column"
                >
                  {languages.map((items) => {
                    return (
                      <ListItem
                        key={items.id}
                        onClick={() => selectNewLanguage(items)}
                        padding="10px 20px"
                        margin={"5px 0"}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          cursor="pointer"
                        >
                          <Image
                            src={items.flag}
                            alt="photo"
                            w="28px"
                            h="28px"
                            borderRadius="25"
                          />
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            margin="0 6px"
                            color="#000"
                          >
                            {items.name}
                          </Text>
                        </Box>
                      </ListItem>
                    );
                  })}
                </UnorderedList>
              </Box>
            </Fade>
          </Stack>
        </Box>
      </Box>
      {/* Mobile */}
      <Box w="80%" mx={"auto"} className="mobile-banner">
        <Box>
          <Text
            fontSize="36px"
            fontFamily="ProximaNova"
            fontWeight="800"
            lineHeight="1.1"
            color="#fff"
            paddingBottom="0"
            marginBottom="0"
          >
            {i18n.t("welcome_banner")} <br />
            JIFU
            <Link to={"/live"} style={{ color: "rgba(211, 7, 7, 1)" }}>
              {" "}
              LIVE TV
            </Link>
          </Text>
        </Box>
        <Box
          display="flex"
          flexDir={"column"}
          alignItems="flex-start"
          py="15px"
        >
          <Text
            color="#fff"
            fontSize="14px"
            fontFamily="ProximaNova"
            fontWeight="400"
            w="100%"
            pb="5px"
            ps="10px"
          >
            {i18n.t("choose_language")}
          </Text>
          <Stack position="relative" w="115px" mx="5px">
            {newCurrentLanguage && (
              <Box
                bg={"rgba(240, 240, 250, 1)"}
                marginTop={"10px"}
                display="flex"
                alignItems="center"
                cursor="pointer"
                justifyContent="flex-start"
                onClick={() => setLangDrop(!langDrop)}
                borderRadius="20px"
                padding={"8px 20px"}
                width={"152px"}
              >
                <Image
                  src={newCurrentLanguage.flag}
                  alt="photo"
                  w="28px"
                  h="28px"
                  borderRadius="25"
                />
                <Text
                  fontSize="14px"
                  fontFamily="ProximaNova"
                  fontWeight="400"
                  color="#rgba(0, 0, 0, 1)"
                  padding={"0"}
                  margin={"0"}
                  ml="20px"
                >
                  {newCurrentLanguage.name}
                </Text>
              </Box>
            )}

            <Fade in={langDrop}>
              <Box
                w="146px"
                h="auto"
                top="57px"
                position="absolute"
                left={"0"}
                borderRadius="15px"
                bg="white"
                zIndex={"1111"}
              >
                <UnorderedList
                  padding="0"
                  margin="0"
                  listStyleType="none"
                  display="flex"
                  alignItems="flex-start"
                  justifyContent={"center"}
                  flexDir="column"
                >
                  {languages.map((items) => {
                    return (
                      <ListItem
                        padding="10px 10px"
                        onClick={() => selectNewLanguage(items)}
                        margin={"5px 0"}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          cursor="pointer"
                        >
                          <Image
                            src={items.flag}
                            alt="photo"
                            w="28px"
                            h="28px"
                            borderRadius="25"
                          />
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            margin="0 6px"
                            color="#000"
                          >
                            {items.name}
                          </Text>
                        </Box>
                      </ListItem>
                    );
                  })}
                </UnorderedList>
              </Box>
            </Fade>
          </Stack>
        </Box>
      </Box>
    </VStack>
  );
};

export default Banner;
