import { Box, Button, IconButton, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Img } from "../../../content/Content";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { useRouter } from "../../../hooks/use-router";
import { AiFillHeart, AiOutlineDelete, AiOutlineEdit, AiOutlineFileImage, AiOutlineHeart, AiOutlineVideoCameraAdd, AiTwotoneCamera } from "react-icons/ai";
import { doc, setDoc } from "firebase/firestore";
import { firestore, storage } from "../../../util/firebase";
import { v4 } from "uuid";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const VideoCarousel = (props) => {
  const { administrator, videos, educator, addToFavorite} = props;

  const inputFile = useRef(null);
  const [ currentVideo, setCurrentVideo ] = useState();

  const router = useRouter();
  const slider = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    nav: true,
    nextArrow: <FaAngleRight />,
    prevArrow: <FaAngleLeft />,
  };

  const selectedVideo = (video) => {
    const url = video.url; // Replace with the actual URL
    // window.open(url, "_blank");
    router.push(`/recorded-session/${video.id}`);
  };

  const convertTitleToDefaultTitle = (titleString) => {
    let title = "";
    // Split the string by the "-" character
    const parts = titleString.split("-");
    // Get the part after the "-"

    if (parts.length) {
      parts.forEach((el, i) => {
        if (i !== 0) {
          // remove the video extention
          title += (i === parts.length - 1) ? el.split(".")[0] : el;
        }
      });
    }

    const result = title;
    // Remove the ".m3u8" extension from the end
    const resultWithoutExtension = result.replace(".m3u8", "");
    return resultWithoutExtension;
  };

  const convertTimestampToDateString = (timestampString) => {
    const date = new Date(timestampString);
    // Format date to "MMM dd, yyyy HH:mm"
    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };

  const deleteVideoPermanently = async (video) => {
    const documentRef = doc(firestore, 'recorded_sessions', video.id);
    setDoc(documentRef, {deleted: true}, {merge:true}).then(() => {
      router.refresh();
    });
  };

  const addRecordingToFavorites = (video) => {
    const documentRef = doc(firestore, 'recorded_sessions', video.id);
    setDoc(documentRef, {favorited: !video.isFavorite}, {merge:true}).then(() => {
      router.refresh();
    })
  };

  const uploadThumbnail = (video) => {
    localStorage.setItem('SelectedVideo', video.id);
    inputFile.current.click();
  };

  useEffect(() => {
    slider.current?.slickGoTo(0)
  }, [videos]);

  useEffect(() => {
    inputFile.current.addEventListener("change", handleFiles, false);
  },[]);

  async function handleFiles() {
    const fileList = this.files; /* now you can work with the file list */
    if (fileList.length > 0) {
      const selectedFile = fileList[0];
      console.log(selectedFile);
      if (selectedFile.type.startsWith("image/")) {
        console.log("You selected an image");
        const uid = v4();
        // Create a reference to 'mountains.jpg'
        const fileRef = ref(storage, `recorded_sessions/thumbnails/${uid}_${selectedFile.name}`);

        await uploadBytes(fileRef, selectedFile).then(async (snapshot) => {
          console.log(snapshot);
          console.log('File uploaded successfully');

          const currentStoredVideo = localStorage.getItem('SelectedVideo');

          await getDownloadURL(snapshot.ref).then(async (downloadURL) => {
            // `url` is the download URL for 'images/stars.jpg'
            console.log(downloadURL);
            console.log(currentStoredVideo);
            const firestoreDocRef = doc(firestore, "recorded_sessions", currentStoredVideo);
            
            await setDoc(firestoreDocRef, {thumbnail: downloadURL}, {merge:true}).then(() => {
              console.log('Successfully stored image in document');
              localStorage.removeItem('SelectedVideo');
              router.refresh();
            }).catch((storeError) => {
              localStorage.removeItem('SelectedVideo');
              console.log(storeError);
            });
          })
          .catch((error) => {
            // Handle any errors
          });
        }).catch((uploadError) => {
          console.log('Failed...');
          console.log(uploadError);
        });
      } else {
        console.log("You selected a document")
      };
    };
  };

  return (
    <>
    <input type='file' id='file' ref={inputFile} style={{display: 'none'}}/>
      <VStack w="90%" mx={"auto"} my="50px" height={"auto"}>
        <Stack w="100%" height={"100%"}>
          <Slider ref={slider} {...settings} adaptiveHeight >
            {videos.map((item) => (
              <Box key={videos.id} w="100%" p="10px" height={"100%"}>
                <Stack
                  boxShadow=" 0px 0px 9px 0px rgba(0, 0, 0, 0.1)"
                  bg="rgba(255, 255, 255, 1)"
                  h="auto"
                  w="100%"
                  margin="auto"
                  borderRadius="18px"
                >
                  {/* top bar */}
                  <Box
                    w="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    px="15px"
                    pt="15px"
                    pb="5px"
                  >
                    <Text
                      w="70%"
                      fontFamily="'Roboto', sans-serif"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(101, 102, 115, 1)"
                    >
                      {item.name}
                    </Text>
                    <Text
                      w="30%"
                      fontFamily="'Roboto', sans-serif"
                      fontSize="12px"
                      fontWeight="400"
                      color="rgba(149, 151, 171, 1)"
                    >
                      {convertTimestampToDateString(item.time)}
                    </Text>
                  </Box>
                  {/* Video Box */}
                  <Box
                    height={"auto"}
                    position={"relative"}
                    w="100%"
                    px="15px"
                    pb="15px"
                  >
                    <Image
                      src={item.img ?? Img.placeholderCoverImage}
                      alt={"photo"}
                      w="100%"
                      fit={"cover"}
                      height={"140px"}
                      borderRadius={"8px"}
                    />

                    <Stack
                      gap="0"
                      bottom="0"
                      position="absolute"
                      top="0"
                      left={"0"}
                      right={"0"}
                      w="100%"
                      height={"100%"}
                    >
                      <Stack h="33.3%"></Stack>
                      <Stack
                        h="7.3%"
                        flexDir="row"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => selectedVideo(item)}
                        cursor={"pointer"}
                      >
                        <Button>
                          <Image src={Img.VideoPlay} />
                        </Button>
                      </Stack>
                    </Stack>
                    {administrator === true && (
                      <Stack flexDirection="row" paddingTop={2}>
                        <IconButton
                        icon={<AiOutlineDelete/>}
                          onClick={() => deleteVideoPermanently(item)}
                        >
                        </IconButton>
                      
                        <Stack >
                          <IconButton
                            onClick={() => addRecordingToFavorites(item)}
                            width="30px" aria-label='Add to Favorites' icon={ item.isFavorite? <AiFillHeart/> : <AiOutlineHeart />} />
                        </Stack>
                        <IconButton
                        icon={<AiTwotoneCamera/>}
                          onClick={() => uploadThumbnail(item)}
                        >
                        </IconButton>
                      </Stack>
                    )}
                  </Box>
                </Stack>
              </Box>
            ))}
          </Slider>
        </Stack>
      </VStack>
    </>
  );
};

export default VideoCarousel;

VideoCarousel.propTypes = {
  administrator: PropTypes.bool,
  videos: PropTypes.any,
};
