import { Stack, VStack, Text, Image, FormControl, FormLabel, Input, Box, Switch, Link, Button, InputGroup, InputRightElement } from "@chakra-ui/react";
import { ErrorMessage, Formik } from "formik";
import { useState } from "react";
import { MobileView } from "react-device-detect";
import { Img } from "../../../../content/Content";
import i18n from "../../../../i18n/config";

const LoginMobileView = ({ tryUserSignIn, isLoading, setIsloading }) => {
    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)

    return (<MobileView>
        <VStack w="100%" alignItems={"center"} gap="0" flexDir={"row"} opacity={isLoading && 0.5}>
            {/* left side */}
            {/* right side */}
            <Stack w="100%" gap="0" position={"relative"} h="100%" pt="100px">
                <Stack px="50px">
                    <Stack py="30px">
                        <Image src={Img.loginLogo} alt="logo" w="153px" h="28.41px" />
                    </Stack>

                    <Stack>
                        {/*  */}
                        <Text
                            fontSize="20px"
                            fontWeight={"700"}
                            fontFamily={"font-family: 'Poppins', sans-serif;"}
                            color="rgba(26, 26, 26, 1)"
                        >
                            {i18n.t('welcome_banner')} JIFU TV
                        </Text>
                    </Stack>

                    {/* form */}

                    <Stack w="100%">
                        <Formik
                            initialValues={{ emailOrUsername: '', password: '' }}
                            validate={values => {
                                const errors = {};
                                if (!values.emailOrUsername) {
                                    errors.emailOrUsername = 'Required';
                                } else {
                                    const isEmail = values.emailOrUsername.includes('@');
                                    const isUsername = /^[a-zA-Z0-9_]{4,}$/.test(values.emailOrUsername);

                                    if (!isEmail && !isUsername) {
                                        errors.emailOrUsername = 'Invalid email or username';
                                    }
                                }

                                if (!values.password) {
                                    errors.password = 'Required'
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setIsloading(true)
                                setTimeout(() => {
                                    tryUserSignIn(values.emailOrUsername, values.password)
                                    setSubmitting(false);
                                }, 400);
                            }}
                        >
                            {({
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <FormControl w="100%">
                                        <FormLabel
                                            fontSize="11px"
                                            fontWeight={"400"}
                                            fontFamily={"font-family: 'Poppins', sans-serif;"}
                                            color="rgba(26, 26, 26, 1)"
                                            px="5px"
                                        >
                                            {"Email or (username) "}
                                        </FormLabel>
                                        <Input
                                            type="text"
                                            name="emailOrUsername"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.emailOrUsername}
                                            bg="rgba(229, 229, 229, 1)"
                                            border="0.5px solid rgba(229, 229, 229, 1)"
                                            placeholder="Username or Email Address"
                                            w="100%"
                                            h="48px"
                                            px="25px"
                                            borderRadius={"10px"}
                                            my="5px"
                                            fontSize="15px"
                                            fontWeight={"400"}
                                            fontFamily={"font-family: 'Roboto', sans-serif;"}
                                            color="rgba(128, 128, 128, 1)"
                                            _focusWithin={{
                                                border: "0",
                                                outline: "none",
                                            }}
                                        />
                                        <ErrorMessage name="emailOrUsername" render={msg => <span style={{ color: 'red', paddingLeft: 4, paddingBottom: 4 }}>{msg}</span >} />
                                    </FormControl>

                                    <FormControl w="100%" position={"relative"}>
                                        <FormLabel
                                            fontSize="11px"
                                            fontWeight={"400"}
                                            fontFamily={"font-family: 'Poppins', sans-serif;"}
                                            color="rgba(26, 26, 26, 1)"
                                            px="5px"
                                        >
                                            Password{" "}
                                        </FormLabel>
                                        <InputGroup size='md'>
                                            <Input
                                                type={show ? "text" : "password"}
                                                name="password"
                                                bg="rgba(229, 229, 229, 1)"
                                                pr='4.5rem'
                                                placeholder='Enter password'
                                                borderRadius={"10px"}
                                                h="48px"
                                                fontSize="15px"
                                                value={values.password}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fontFamily={"font-family: 'Roboto', sans-serif;"}
                                                color="rgba(128, 128, 128, 1)"
                                            />
                                            <InputRightElement width='4.5rem'>
                                                <Button h='1.75rem' size='sm' onClick={handleClick}>
                                                    {show ? 'Hide' : 'Show'}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                        <ErrorMessage name="password" render={msg => <span style={{ color: 'red', paddingLeft: 4, paddingBottom: 4 }}>{msg}</span >} />
                                    </FormControl>
                                    <Box
                                        display="flex"
                                        py="10px"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        w="100%"
                                    >
                                        <FormControl display="flex" alignItems="center">
                                            <Switch id="email-alerts" />
                                            <FormLabel
                                                htmlFor="email-alerts"
                                                mx="4px"
                                                mb="0"
                                                fontSize="12px"
                                                fontWeight={"400"}
                                                fontFamily={"font-family: 'Poppins', sans-serif;"}
                                                color="rgba(26, 26, 26, 1)"
                                            >
                                                Remember me
                                            </FormLabel>
                                        </FormControl>
                                        <Link
                                            href="https://office2.jifu.com/#/Login"
                                            w="50%"
                                            textAlign={"end"}
                                            fontSize="12px"
                                            fontWeight={"400"}
                                            fontFamily={"font-family: 'Poppins', sans-serif;"}
                                            color="rgba(0, 122, 255, 1)"
                                        >
                                            Forgot password?
                                        </Link>
                                    </Box>
                                    <Button
                                        fontSize="15px"
                                        fontWeight={"700"}
                                        fontFamily={"font-family: 'Roboto', sans-serif;"}
                                        w="100%"
                                        textAlign="center"
                                        bg="rgba(0, 122, 255, 1)"
                                        h="40px"
                                        color="#fff"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Sign in
                                    </Button>
                                </form>)
                            }
                        </Formik>
                    </Stack>
                    <Stack position="absolute" bottom="-106px" left={"0"} right="0">
                        <Text
                            textAlign="center"
                            color="rgba(102, 102, 102, 1)"
                            fontSize="12px"
                            fontWeight={"400"}
                            fontFamily={"font-family: 'Poppins', sans-serif;"}
                        >
                            © 2023 JIFU
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
        </VStack>
    </MobileView>)
}


export default LoginMobileView;