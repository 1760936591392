import {
    Box,
    Container,
    Image,
    Stack,
    Text,
    VStack,
    Button,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { Img } from "../../../../content/Content";
  import { GoArrowUpRight } from "react-icons/go";
  import "./channel.component.css";
  import { collection, where, getDocs, query } from "firebase/firestore";
  import { firestore } from "../../../../util/firebase";
  import { currentLanguage } from "../../../../util/language";
  import { useRouter } from "../../../../hooks/use-router";
  import i18n from "../../../../i18n/config";
  
  const Channel = () => {
    const router = useRouter();
    const [allChannels, setAllChannels] = useState([]);
  
    const [showSlide, setSlide] = useState(false);
    const productChannel = showSlide ? allChannels.slice(0, 10) : allChannels.slice(0, 4);
  
    const fetchChannels = async () => {
      const currentLanguageId = currentLanguage().id ?? "en";
  
      const q = query(
        collection(firestore, "academy"),
        where("language", "==", currentLanguageId)
      );
  
      try {
        const allDocs = await getDocs(q);
        if (allDocs.empty === false) {
          const parsedChannels = allDocs.docs.map((document) => {
            const data = document.data();
  
            var backgroundImage = "";
            if (data.banner === "" || data.banner === undefined) {
              backgroundImage = Img.placeholderCoverImage;
            } else {
              backgroundImage = data.banner;
            }
  
            const channel = {
              id: document.id,
              name: data.title,
              logo: data.logo,
              pic: backgroundImage,
              language: data.language,
              color: data.color,
              description: data.description,
              order: data.order ?? 0,
            };
            return channel;
          });

          const sortedChannels = parsedChannels.sort((a , b) => a.order - b.order);
          setAllChannels(sortedChannels);
        }
      } catch (error) {
        console.log("Failed: " + error);
      }
    };
  
    const selectedChannel = (channel) => {
      console.log(`Channel Selected: ${channel.id}`);
      router.push(`/channel/${channel.id}`);
    };
  
    useEffect(() => {
      fetchChannels();
    }, []);
  
    return (
      <VStack bgColor="rgba(245, 245, 255, 1)" paddingBottom={20}>
        {/* desktop-version */}
        <Box w="80%" mx="auto" className="desktop-channel">
          {/* top-bar */}
          <Stack flexDir="row" justifyContent="space-between">
            <Box>
              <Text
                color="rgba(0, 0, 0, 1)"
                fontSize="30px"
                marginBottom={"25px"}
                fontWeight="700"
              >
                {i18n.t("pick_a_channel")}
              </Text>
            </Box>
            
            {/* <Box>
              <Text
                color="rgba(0, 0, 0, 1)"
                fontSize="20px"
                fontWeight={"400"}
                cursor={"pointer"}
              >
                {i18n.t("see_all")}
              </Text>
            </Box> */}
          </Stack>
          <Stack>
            <Box
              height={'1px'}
              width={'100%'}
              backgroundColor={'#DFDFFD'}
              mb={'50px'}
              >

            </Box>
          </Stack>
          {/* all boxes */}
          <Stack
            flexDir="row"
            p="0"
            m="0"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
            w={"100%"}
            gap={"0"}
          >
            {allChannels.map((item) => {
              return (
                <Stack
                  w="33.33333%"
                  p="0"
                  m="0"
                  my="0px"
                  overflow={"hidden"}
                  key={item.id}
                >
                  <Box
                    onClick={() => selectedChannel(item)}
                    bg={
                      item.active === true
                        ? "rgba(96, 31, 235, 1)"
                        : "rgba(255, 255, 255, 1)"
                    }
                    borderRadius="54px"
                    p="30px"
                    m="10px"
                    transition="0.3s all ease-in"
                    cursor={"pointer"}
                    className="channel-box"
                  >
                    {/* top-bar */}
                    <Stack flexDir="row">
                      <Box
                        w="50%"
                        h={"167px"}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <img
                          src={item.logo}
                          alt="chanelLogo1"
                          height={"108px"}
                          w="194px"
                        />
                      </Box>
                      <Box
                        w="50%"
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <Box
                          bg="rgba(234, 234, 234, 1)"
                          height={"100px"}
                          w="100px"
                          borderRadius="90px"
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <GoArrowUpRight style={{ fontSize: "42px" }} />
                        </Box>
                      </Box>
                    </Stack>
                    {/* title */}
                    <Stack my="4">
                      <Text
                        className="title"
                        fontSize="20px"
                        fontWeight={"500"}
                        color={"#000"}
                        lineHeight={"23.44px"}
                        py="15px"
                      >
                        {item.name}
                      </Text>
                    </Stack>
  
                    {/* cover Image */}
                    <Stack
                      height="auto"
                      width={"100%"}
                      borderRadius={"30px"}
                      overflow="hidden"
                    >
                      <Image
                        src={item.pic}
                        alt="cover"
                        w="100%"
                        h="100%"
                        fit={"cover"}
                        objectFit={"cover"}
                      />
                    </Stack>
                  </Box>
                </Stack>
              );
            })}
          </Stack>
        </Box>
        {/* mobile-version */}
        <Box w="80%" mx="auto" className="mobile-channel">
          {/* top-bar */}
          <Stack
            justifyContent="space-between"
            alignItems={"center"}
            w="100%"
            py="25px"
          >
            <Box w="100%">
              <Text
                color="rgba(0, 0, 0, 1)"
                fontSize="24px"
                fontWeight={"700"}
                fontFamily={"ProximaNova"}
                textAlign="center"
              >
                {i18n.t("pick_a_channel")}
              </Text>
            </Box>
          </Stack>
  
          {/* all boxes */}
          <Stack
            p="0"
            m="0"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
            w={"100%"}
            gap={"0"}
          >
            {productChannel.map((item) => {
              return (
                <Stack w="100%" p="0" m="0" my="10px" overflow={"hidden"}>
                  <Box
                    bg={
                      item.active === true
                        ? "rgba(96, 31, 235, 1)"
                        : "rgba(255, 255, 255, 1)"
                    }
                    borderRadius="26px"
                    p="30px"
                    m="10px"
                    transition="0.3s all ease-in"
                    className="channel-box"
                  >
                    {/* top-bar */}
                    <Stack flexDir="row">
                      <Box
                        w="50%"
                        h={"167px"}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <img
                          src={item.logo}
                          alt="chanelLogo1"
                          height={"108px"}
                          w="194px"
                        />
                      </Box>
                      <Box
                        w="50%"
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <Box
                          bg="rgba(234, 234, 234, 1)"
                          height={"100px"}
                          w="100px"
                          borderRadius="90px"
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <GoArrowUpRight style={{ fontSize: "42px" }} />
                        </Box>
                      </Box>
                    </Stack>
                    {/* title */}
                    <Stack my="4">
                      <Text
                        className="title"
                        fontSize="20px"
                        fontWeight={"500"}
                        color={"#000"}
                        lineHeight={"23.44px"}
                        py="15px"
                        fontFamily={"'Roboto', sans-serif"}
                      >
                        {item.name}
                      </Text>
                    </Stack>
  
                    {/* cover Image */}
                    <Stack height="233px" overflow="hidden">
                      <Image
                        src={item.pic}
                        alt="cover"
                        w="100%"
                        h="auto"
                        borderRadius={"30px"}
                      />
                    </Stack>
                  </Box>
                </Stack>
              );
            })}
            <Button
              w="60%"
              mx="auto"
              bg="rgba(0, 113, 193, 1)"
              color="#fff"
              fontSize="20px"
              fontFamily="'Roboto', sans-serif"
              fontWeight="400"
              textAlign="center"
              h="49px"
              _hover={{
                color: "#ffff",
              }}
              onClick={() => setSlide(!showSlide)}
            >
              {i18n.t("load_more")}
            </Button>
          </Stack>
        </Box>
      </VStack>
    );
  };
  
  export default Channel;
  