import React from 'react'
import Routing from './Routing/Routing'
import './i18n/config'


function App() {
  return (
    <div>
      <Routing/>
    </div>
  )
}

export default App
