import { Box, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Img } from "../../../../content/Content";
import { firestore } from "../../../../util/firebase";
import { collection, getDocs, where, query } from "firebase/firestore";
import { useParams } from "react-router-dom";
import i18n from "../../../../i18n/config";
import "./educators.component.css";
import { DateTime } from "luxon";

const Educators = () => {
  const { academyId, educatorId } = useParams();
  const [allAcademies, setAllAcademies] = useState([]);
  const [allEducators, setAllEducators] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const fetchAllAcademies = async () => {
    const educatorsDocRef = collection(firestore, "academy");
    const academyDocumentSnapshots = await getDocs(educatorsDocRef);

    if (academyDocumentSnapshots.empty === false) {
      const parsedAcademies = academyDocumentSnapshots.docs.map(
        (academyDoc) => {
          const academyData = academyDoc.data();

          const academy = {
            id: academyDoc.id,
            logo: academyData.logo,
            name: academyData.title,
            color: academyData.color ?? "#0071C1",
          };
          return academy;
        }
      );
      setAllAcademies(parsedAcademies);
      fetchGeneralEducators();
    }
  };

  const fetchGeneralEducators = async () => {
    const q = query(
      collection(firestore, "educators"),
      where("academy", "==", academyId ?? "en")
    );
    try {
      const allDocs = await getDocs(q);
      if (allDocs.empty === false) {
        const parsedEducators = allDocs.docs.map((document) => {
          const data = document.data();

          var weight = 0;
          if (data.popularity === 0 || data.popularity === undefined) {
            weight = 0;
          } else {
            weight = data.popularity;
          }

          var backgroundImage = "";
          if (data.photo === "" || data.photo === undefined) {
            backgroundImage = Img.blankUserImage;
          } else {
            backgroundImage = data.photo;
          }

          const educator = {
            id: document.id,
            name: data.name,
            academy: data.academy,
            channel: data.channel ?? "",
            logo: data.logo ?? "",
            img: backgroundImage,
            weight: weight,
            color: data.color,
            // tm1: true
          };
          return educator;
        });

        setAllEducators(parsedEducators);

        const q = query(collection(firestore, "sessions"));
        try {
          const alldocuments = await getDocs(q);

          if (alldocuments.empty === false) {
            const parsedEvents = alldocuments.docs.map((docRef) => {
              const data = docRef.data();
              const event = {
                id: docRef.id,
                channel: data.channel_id ?? "",
                educator: data.educator_id ?? "",
                weekday: data.weekday ?? 0,
                description: data.description,
                language: data.language_id ?? "en",
                title: data.title ?? "",
                url: data.url ?? "",
                start: data.start_time,
                end: data.end_time,
                timezone: data.timezone ?? "EST",
              };
              return event;
            });

            var newEvents = [];

            parsedEvents.forEach((session) => {
              const hasItemWithEducator = newEvents.some(
                (obj) => obj.educator === session.educator
              );
              const index = newEvents.findIndex(
                (item) => item.educator === session.educator
              );

              if (hasItemWithEducator === true) {
                const individualEvent = {
                  title: session.title,
                  start: session.start,
                  timezone: session.timezone,
                  end: session.end,
                };

                if (session.weekday === 0) {
                  newEvents[index].sun.push(individualEvent);
                } else if (session.weekday === 1) {
                  newEvents[index].mon.push(individualEvent);
                } else if (session.weekday === 2) {
                  newEvents[index].tue.push(individualEvent);
                } else if (session.weekday === 3) {
                  newEvents[index].wed.push(individualEvent);
                } else if (session.weekday === 4) {
                  newEvents[index].thu.push(individualEvent);
                } else if (session.weekday === 5) {
                  newEvents[index].fri.push(individualEvent);
                } else if (session.weekday === 6) {
                  newEvents[index].sat.push(individualEvent);
                } else {
                  //
                }
              } else {
                const newItem = {
                  id: session.id,
                  educator: session.educator,
                  name: "",
                  sun: [],
                  mon: [],
                  tue: [],
                  wed: [],
                  thu: [],
                  fri: [],
                  sat: [],
                  bgColor2: "",
                  url: session.url,
                };

                const individualEvent = {
                  title: session.title,
                  start: session.start,
                  end: session.end,
                  timezone: session.timezone,
                };

                if (session.weekday === 0) {
                  newItem.sun = [individualEvent];
                } else if (session.weekday === 1) {
                  newItem.mon = [individualEvent];
                } else if (session.weekday === 2) {
                  newItem.tue = [individualEvent];
                } else if (session.weekday === 3) {
                  newItem.wed = [individualEvent];
                } else if (session.weekday === 4) {
                  newItem.thu = [individualEvent];
                } else if (session.weekday === 5) {
                  newItem.fri = [individualEvent];
                } else if (session.weekday === 6) {
                  newItem.sat = [individualEvent];
                } else {
                  //
                }

                newEvents.push(newItem);
              }
            });

            const flatenedEducators = parsedEducators.map((educator) => {
              return educator.id
            });
            
            const filteredObjects = newEvents.filter(obj => flatenedEducators.includes(obj.educator));

            if (educatorId !== undefined && educatorId !== '') {
              const filtered = filteredObjects.filter((event) => event.educator === educatorId);
              setFilteredEvents(filtered);
            } else {
              setFilteredEvents(filteredObjects);
            };
          }
        } catch (error_2) {
          console.log(error_2);
        }
      }
    } catch (error) {
      console.log("Failed: " + error);
    }
  };

  const fetchAllSessions = async () => {};

  const getAcademyNameForId = (targetAcademyId) => {
    if (allAcademies.length > 0) {
      const filtered = allAcademies.filter(
        (academy) => academy.id === targetAcademyId
      );
      if (filtered.length > 0) {
        return filtered[0].name;
      } else {
        return targetAcademyId;
      }
    } else {
      return targetAcademyId;
    }
  };

  const fetchObjectForDay = (weekday, educator, selectedTimezone) => {
    const parsedEvents = filteredEvents.filter((event) => event.educator === educator.id);

    const response = "undefined"

    if (parsedEvents.length > 0) {
      const targetEventObj = parsedEvents[0];
      if (weekday === 0) {
        // sun
       const events = targetEventObj.sun;
       if (events.length > 0) {
        return convertTime(events, selectedTimezone);
       } else {
        return response
       }
      } else if (weekday === 1) {
        // mon
        const events = targetEventObj.mon;
        if (events.length > 0) {
          return convertTime(events, selectedTimezone);
         } else {
          return response
         }
      } else if (weekday === 2) {
        // tue
        const events = targetEventObj.tue;
        if (events.length > 0) {
          return convertTime(events, selectedTimezone);
         } else {
          return response
         }
      } else if (weekday === 3) {
        // wed
        const events = targetEventObj.wed;
        if (events.length > 0) {
          return convertTime(events, selectedTimezone);
         } else {
          return response
         }
      } else if (weekday === 4) {
        // thu
        const events = targetEventObj.thu;
        if (events.length > 0) {
          return convertTime(events, selectedTimezone);
         } else {
          return response
         }
      } else if (weekday === 5) {
        // fri
        const events = targetEventObj.fri;
        if (events.length > 0) {
          return convertTime(events, selectedTimezone);
         } else {
          return response
         }
      } else {
        // sat
        const events = targetEventObj.sat;
        if (events.length > 0) {
          return convertTime(events, selectedTimezone);
         } else {
          return response
         }
      }
    } else {
      return response
    };
  };

  const convertTime = (events, selectedTimezone) => {
    const starttime = events[0].start;
    const timezone = events[0].timezone ?? 'EST';
    
    if (starttime !== undefined) {
      var currentTime = ''
      if (selectedTimezone === "CST") {
        if (timezone === "EST" || timezone === '') {
          const easternTime = DateTime.fromFormat(starttime, "h:mm a", { zone: "America/New_York" });
          const centralTime = easternTime.setZone("America/Chicago");
          const result = centralTime.toFormat("h:mm a");
          currentTime = result;
        } else {
          currentTime = starttime;
        }
      } else {
        if (timezone === "CST") {
          const centralTime = DateTime.fromFormat(starttime, "h:mm a", { zone: "America/Chicago" });
          const easternTime = centralTime.setZone("America/New_York"); //New_York
          const result = easternTime.toFormat("h:mm a");
          currentTime = result;
        } else {
          currentTime = starttime;
        }
      };

      // const timeWithoutAMPM = currentTime.replace(/\s+[APap][Mm]\s*$/, ''); // Remove AM/PM
      return currentTime;
    } else {
      return '';
    };
    
  }

  const getAcademyLogoForId = (targetAcademyId) => {
    console.log(targetAcademyId);
    if (allAcademies.length > 0) {
      const filtered = allAcademies.filter(
        (academy) => academy.id === targetAcademyId
      );
      if (filtered.length > 0) {
        return filtered[0].logo;
      } else {
        return targetAcademyId;
      }
    } else {
      return targetAcademyId;
    }
  };

  useEffect(() => {
    fetchAllAcademies();
  }, []);

  return (
    <VStack py="35px" w="100%" overflow={"hidden"}>
      {/* desktop-version */}
      <Stack
        className="desktop-version"
        flexDir="row"
        justifyContent="space-between"
        alignItems={"center"}
        w="100%"
      >
        <Box>
          <Text
            color="rgba(0, 0, 0, 1)"
            fontSize="26px"
            fontWeight={"700"}
            fontFamily={"font-family: 'Poppins', sans-serif;"}
          >
            {i18n.t("general_educators")}
          </Text>
        </Box>
        <Box>
          <Text
            color="rgba(0, 0, 0, 1)"
            fontSize="20px"
            fontWeight={"400"}
            cursor={"pointer"}
            fontFamily={"ProximaNova"}
          >
            {i18n.t("see_all")}
          </Text>
        </Box>
      </Stack>

      <Stack
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        flexWrap={"wrap"}
        gap={"0"}
        w="100%"
      >
        {allEducators.map((item) => (
          <Box w="25%">
            <Box
              h="auto"
              boxShadow="0px 0px 11px 0px rgba(0, 0, 0, 0.13)"
              borderRadius="18px"
              margin="15px"
            >
              <Box textAlign="center" py="20px">
                <Text fontSize="18px" fontWeight="400" color="rgba(0, 0, 0, 1)">
                  {getAcademyNameForId(item.academy)}
                </Text>
                <Text
                  color="rgba(0, 0, 0, 1)"
                  fontSize="20px"
                  fontWeight="700"
                  mt="0"
                >
                  {item.name}
                </Text>
              </Box>
              {/* Days */}
              <Stack flexDir="row" p="18px">
                <Box w="33%" gap={'0'}>
                  <Text
                    fontSize="16px"
                    fontWeight={"600"}
                    fontFamily={"font-family: 'Poppins', sans-serif;"}
                    color="rgba(0, 0, 0, 1)"
                  >
                    EST
                  </Text>
                  {/* SUN */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(0, item, 'EST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      noOfLines={1}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mb="2px"
                      minH={'18px'}
                    >
                      {fetchObjectForDay(0, item, 'EST') === 'undefined' ? "" : fetchObjectForDay(1, item, 'EST')}
                    </Text>
                  </Box>
                  {/* MON */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(1, item, 'EST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      noOfLines={1}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mb="2px"
                      minH={'18px'}
                    >
                      {fetchObjectForDay(1, item, 'EST') === 'undefined' ? "" : fetchObjectForDay(1, item, 'EST')}
                    </Text>
                  </Box>
                  {/* TUE */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(2, item, 'EST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      noOfLines={1}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mb="2px"
                      minH={'18px'}
                    >
                      {fetchObjectForDay(2, item, 'EST') === 'undefined' ? "" : fetchObjectForDay(2, item, 'EST')}
                    </Text>
                  </Box>
                  {/* WED */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(3, item, 'EST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      noOfLines={1}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mb="2px"
                      minH={'18px'}
                    >
                      {fetchObjectForDay(3, item, 'EST') === 'undefined' ? "" : fetchObjectForDay(3, item, 'EST')}
                    </Text>
                  </Box>
                  {/* THU */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(4, item, 'EST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      noOfLines={1}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mb="2px"
                      minH={'18px'}
                    >
                      {fetchObjectForDay(4, item, 'EST') === 'undefined' ? "" : fetchObjectForDay(4, item, 'EST')}
                    </Text>
                  </Box>
                  {/* FRI */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(5, item, 'EST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      noOfLines={1}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mb="2px"
                      minH={'18px'}
                    >
                      {fetchObjectForDay(5, item, 'EST') === 'undefined' ? "" : fetchObjectForDay(5, item, 'EST')}
                    </Text>
                  </Box>
                  {/* SAT */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(6, item, 'EST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      noOfLines={1}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mt="0px"
                      minH={'18px'}
                    >
                      {fetchObjectForDay(6, item, 'EST') === 'undefined' ? "" : fetchObjectForDay(6, item, 'EST')}
                    </Text>
                  </Box>
                </Box>
                <Box w="33%">
                  <Text
                    textAlign="center"
                    fontSize="16px"
                    fontWeight={"600"}
                    fontFamily={"font-family: 'Poppins', sans-serif;"}
                    color="rgba(0, 0, 0, 1)"
                  >
                    Day
                  </Text>
                  <Box display="flex" flexDirection={"column"} gap={'0'}>
                    <Text
                      textAlign="center"
                      fontSize="14px"
                      fontWeight={"400"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(0, 0, 0, 1)"
                    >
                      Sun
                    </Text>
                    <Text
                      textAlign="center"
                      fontSize="14px"
                      fontWeight={"400"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(96, 31, 235, 1)"
                    >
                      Mon
                    </Text>
                    <Text
                      textAlign="center"
                      fontSize="14px"
                      fontWeight={"400"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(96, 31, 235, 1)"
                    >
                      Tue
                    </Text>
                    <Text
                      textAlign="center"
                      fontSize="14px"
                      fontWeight={"400"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(0, 0, 0, 1)"
                    >
                      Wed
                    </Text>
                    <Text
                      textAlign="center"
                      fontSize="14px"
                      fontWeight={"400"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(0, 0, 0, 1)"
                    >
                      Thu
                    </Text>
                    <Text
                      textAlign="center"
                      fontSize="14px"
                      fontWeight={"400"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(96, 31, 235, 1)"
                    >
                      Fri
                    </Text>

                    <Text
                      textAlign="center"
                      fontSize="14px"
                      fontWeight={"400"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(0, 0, 0, 1)"
                    >
                      Sat
                    </Text>
                  </Box>
                </Box>
                <Box w="33%">
                  <Text
                    textAlign="right"
                    fontSize="16px"
                    fontWeight={"600"}
                    fontFamily={"font-family: 'Poppins', sans-serif;"}
                    color="rgba(0, 0, 0, 1)"
                  >
                    CST
                  </Text>
                  {/* SUN */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(0, item, 'CST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mb="2px"
                      noOfLines={1}
                      minH={'18px'}
                    >
                      {fetchObjectForDay(0, item, 'CST') === 'undefined' ? "" : fetchObjectForDay(0, item, 'CST')}
                    </Text>
                  </Box>
                  {/* MON */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(1, item, 'CST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mb="2px"
                      noOfLines={1}
                      minH={'18px'}
                    >
                      {fetchObjectForDay(1, item, 'CST') === 'undefined' ? "" : fetchObjectForDay(1, item, 'CST')}
                    </Text>
                  </Box>
                  {/* TUE */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(2, item, 'CST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mb="2px"
                      noOfLines={1}
                      minH={'18px'}
                    >
                      {fetchObjectForDay(2, item, 'CST') === 'undefined' ? "" : fetchObjectForDay(2, item, 'CST')}
                    </Text>
                  </Box>
                  {/* WED */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(3, item, 'CST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mb="2px"
                      noOfLines={1}
                      minH={'18px'}
                    >
                      {fetchObjectForDay(3, item, 'CST') === 'undefined' ? "" : fetchObjectForDay(3, item, 'CST')}
                    </Text>
                  </Box>
                  {/* THU */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(4, item, 'CST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mb="2px"
                      noOfLines={1}
                      minH={'18px'}
                    >
                      {fetchObjectForDay(4, item, 'CST') === 'undefined' ? "" : fetchObjectForDay(4, item, 'CST')}
                    </Text>
                  </Box>
                  {/* FRI */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(5, item, 'CST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mb="2px"
                      noOfLines={1}
                      minH={'18px'}
                    >
                      {fetchObjectForDay(5, item, 'CST') === 'undefined' ? "" : ""}
                    </Text>
                  </Box>
                  {/* SAT */}
                  <Box>
                    <Text
                      borderRadius="37px"
                      bg={fetchObjectForDay(6, item, 'CST') === "undefined" ? 'clear' : 'rgba(149, 151, 171, 1)'}
                      padding={"2px 4px"}
                      fontSize={"10px"}
                      fontWeight={"600"}
                      fontFamily={"font-family: 'Poppins', sans-serif;"}
                      color="rgba(255, 255, 255, 1)"
                      textAlign={"center"}
                      mt="0px"
                      noOfLines={1}
                      minH={'18px'}
                    >
                      {fetchObjectForDay(6, item, 'CST') === 'undefined' ? "" : ""}
                    </Text>
                  </Box>
                </Box>
              </Stack>
              <Box
                w="85%"
                mx="auto"
                h={"303px"}
                position="relative"
                paddingBottom="20px"
              >
                <Image
                  src={getAcademyLogoForId(item.academy)}
                  alt="logo"
                  position={"absolute"}
                  top="10px"
                  left={"10px"}
                  w={"57px"}
                />
                <Image
                  src={item.img}
                  alt="logo"
                  w="100%"
                  borderRadius="32px"
                  h="100%"
                  objectFit={item.tm1 === true ? "contain" : "cover"}
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Stack>

      {/* mobile-version */}
      <Stack w="100%" h="100%" className="mobile-version">
        <Stack justifyContent="space-between" alignItems={"center"} w="100%">
          <Text
            color="rgba(0, 0, 0, 1)"
            fontSize="24px"
            fontWeight={"600"}
            fontFamily={" 'Poppins', sans-serif;"}
          >
            {i18n.t("general_educators")}
          </Text>
        </Stack>

        <Stack
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          flexWrap={"wrap"}
          gap={"0"}
          w="100%"
          pt="30px"
        >
          {allEducators.map((item) => (
            <Box w="100%">
              <Box
                h="auto"
                boxShadow="0px 0px 11px 0px rgba(0, 0, 0, 0.13)"
                borderRadius="18px"
                margin="15px"
              >
                <Box textAlign="center" py="20px">
                  <Text
                    fontSize="20px"
                    fontWeight="400"
                    color="rgba(0, 0, 0, 1)"
                  >
                    {getAcademyNameForId(item.academy)}
                  </Text>
                  <Text
                    color="rgba(0, 0, 0, 1)"
                    fontSize="20px"
                    fontWeight="700"
                    mt="0"
                  >
                    {item.name}
                  </Text>
                </Box>
                {/* Days */}
                <Stack flexDir="row" p="18px">
                  <Box w="33%">
                    <Text
                      fontSize="16px"
                      fontWeight={"600"}
                      fontFamily={" 'Poppins', sans-serif;"}
                      color="rgba(0, 0, 0, 1)"
                    >
                      EST
                    </Text>
                    <Box>
                      <Text
                        borderRadius="37px"
                        bg="rgba(149, 151, 171, 1)"
                        padding={"2px 4px"}
                        fontSize={"10px"}
                        fontWeight={"600"}
                        fontFamily={" 'Poppins', sans-serif;"}
                        color="rgba(255, 255, 255, 1)"
                        textAlign={"center"}
                        mt="20px"
                      >
                        {item.time2}
                      </Text>
                    </Box>
                  </Box>
                  <Box w="33%">
                    <Text
                      textAlign="center"
                      fontSize="16px"
                      fontWeight={"600"}
                      fontFamily={" 'Poppins', sans-serif;"}
                      color="rgba(0, 0, 0, 1)"
                    >
                      Day
                    </Text>
                    <Box display="flex" flexDirection={"column"}>
                      <Text
                        textAlign="center"
                        fontSize="14px"
                        fontWeight={"400"}
                        fontFamily={" 'Poppins', sans-serif;"}
                        color="rgba(0, 0, 0, 1)"
                      >
                        Sun
                      </Text>
                      <Text
                        textAlign="center"
                        fontSize="14px"
                        fontWeight={"400"}
                        fontFamily={" 'Poppins', sans-serif;"}
                        color="rgba(96, 31, 235, 1)"
                      >
                        Mon
                      </Text>
                      <Text
                        textAlign="center"
                        fontSize="14px"
                        fontWeight={"400"}
                        fontFamily={" 'Poppins', sans-serif;"}
                        color="rgba(0, 0, 0, 1)"
                      >
                        Wed
                      </Text>
                      <Text
                        textAlign="center"
                        fontSize="14px"
                        fontWeight={"400"}
                        fontFamily={" 'Poppins', sans-serif;"}
                        color="rgba(0, 0, 0, 1)"
                      >
                        Thu
                      </Text>
                      <Text
                        textAlign="center"
                        fontSize="14px"
                        fontWeight={"400"}
                        fontFamily={" 'Poppins', sans-serif;"}
                        color="rgba(96, 31, 235, 1)"
                      >
                        Fri
                      </Text>

                      <Text
                        textAlign="center"
                        fontSize="14px"
                        fontWeight={"400"}
                        fontFamily={" 'Poppins', sans-serif;"}
                        color="rgba(0, 0, 0, 1)"
                      >
                        Sat
                      </Text>
                    </Box>
                  </Box>
                  <Box w="33%">
                    <Text
                      textAlign="right"
                      fontSize="16px"
                      fontWeight={"600"}
                      fontFamily={" 'Poppins', sans-serif;"}
                      color="rgba(0, 0, 0, 1)"
                    >
                      CDT
                    </Text>
                    <Box>
                      <Text
                        borderRadius="37px"
                        bg="rgba(149, 151, 171, 1)"
                        padding={"2px 4px"}
                        fontSize={"10px"}
                        fontWeight={"600"}
                        fontFamily={" 'Poppins', sans-serif;"}
                        color="rgba(255, 255, 255, 1)"
                        textAlign={"center"}
                        mt="20px"
                      >
                        {item.time1}
                      </Text>
                    </Box>
                  </Box>
                </Stack>
                <Box
                  w="85%"
                  mx="auto"
                  h={"303px"}
                  position="relative"
                  paddingBottom="20px"
                >
                  <Image
                    src={item.logo}
                    alt="logo"
                    position={"absolute"}
                    top="10px"
                    left={"10px"}
                    w={"57px"}
                  />
                  <Image
                    src={item.img}
                    alt="logo"
                    w="100%"
                    borderRadius="32px"
                    h="100%"
                    objectFit={item.tm1 === true ? "contain" : "cover"}
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Stack>
      </Stack>
    </VStack>
  );
};

export default Educators;
