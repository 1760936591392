import { Box, Image, Link, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Img } from "../../../../content/Content";
import "./calender.component.css";
import { useParams } from "react-router-dom";
import { useRouter } from "../../../../hooks/use-router";

import { firestore } from "../../../../util/firebase";
import { collection, getDocs, query, getDoc, doc } from "firebase/firestore";

const Calender = () => {
  const router = useRouter();
  const { academyId, educatorId, timezoneId } = useParams();
  // const [academyEducatorIds, setAcademyEducatorIds] = useState([]);
  // const [calendarEvents, setCalendarEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [allEducators, setAllEducators] = useState([]);

  const selectedEvent = (event) => {
    router.push(`/session/${event.id}`);
  };

  const convertTimezone = (time, zone) => {
    const inputTimeString = `${time} ${zone}`;
    const inputTime = new Date(`January 1, 2023 ${inputTimeString}`);

    // Eastern Standard Time
    const est = inputTime.toLocaleString("en-US", {
      timeZone: "America/New_York",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    // Central Standard Time
    const cst = inputTime.toLocaleString("en-US", {
      timeZone: "America/Chicago",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    // Mountain Standard Time
    const mst = inputTime.toLocaleString("en-US", {
      timeZone: "America/Denver",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    // Pacific Standard Time
    const pst = inputTime.toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    let value = "";

    if (timezoneId === "EST") {
      value = est;
    } else if (timezoneId === "CST") {
      value = cst;
    } else if (timezoneId === "MST") {
      value = mst;
    } else if (timezoneId === "PST") {
      value = pst;
    } else {
      value = est;
    }

    if (timezoneId === undefined) {
      return `${value} EST`;
    } else {
      return `${value} ${timezoneId}`;
    };
  };

  const selectedEducator = (educator) => {
    // console.log(educator);
  };

  const fetchAllEducators = async () => {
    const colRef = collection(firestore, "educators");
    try {
      const fetchedDocs = await getDocs(colRef);
      if (fetchedDocs.empty === false) {
        const parsedEducators = fetchedDocs.docs.map((educatorDoc) => {
          const data = educatorDoc.data();

          var photo = "";
          if (data.photo === undefined || data.photo === "") {
            photo = Img.blankUserImage;
          } else {
            photo = data.photo;
          };

          const educator = {
            id: educatorDoc.id,
            name: data.name,
            photo: photo,
            language: data.language,
            academy: data.academy,
          };
          return educator;
        });

        setAllEducators(parsedEducators);

        const docRef = doc(firestore, "academy", academyId);
        try {
          const educatorDoc = await getDoc(docRef);
          if (educatorDoc.exists() === true) {
            const data = educatorDoc.data();
            const academyEducators = data.educators ?? [];
            // setAcademyEducatorIds(academyEducators);
            if (academyId !== undefined) {
              const q = query(collection(firestore, "sessions"));
              try {
                const alldocuments = await getDocs(q);

                if (alldocuments.empty === false) {
                  const parsedEvents = alldocuments.docs.map((docRef) => {
                    const data = docRef.data();

                    const event = {
                      id: docRef.id,
                      channel: data.channel_id ?? "",
                      educator: data.educator_id ?? "",
                      weekday: data.weekday ?? 0,
                      description: data.description,
                      language: data.language_id ?? "en",
                      title: data.title ?? "",
                      url: data.url ?? "",
                      start: data.start_time,
                      end: data.end_time,
                      timezone: data.timezone ?? "EST",
                    };
                    return event;
                  });

                  var newEvents = [];

                  parsedEvents.forEach((session) => {
                    const hasItemWithEducator = newEvents.some(
                      (obj) => obj.educator === session.educator
                    );
                    const index = newEvents.findIndex(
                      (item) => item.educator === session.educator
                    );

                    if (hasItemWithEducator === true) {
                      const individualEvent = {
                        title: session.title,
                        start: session.start,
                        timezone: session.timezone,
                        end: session.end,
                      };

                      if (session.weekday === 0) {
                        newEvents[index].sun.push(individualEvent);
                      } else if (session.weekday === 1) {
                        newEvents[index].mon.push(individualEvent);
                      } else if (session.weekday === 2) {
                        newEvents[index].tue.push(individualEvent);
                      } else if (session.weekday === 3) {
                        newEvents[index].wed.push(individualEvent);
                      } else if (session.weekday === 4) {
                        newEvents[index].thu.push(individualEvent);
                      } else if (session.weekday === 5) {
                        newEvents[index].fri.push(individualEvent);
                      } else if (session.weekday === 6) {
                        newEvents[index].sat.push(individualEvent);
                      } else {
                        //
                      }
                    } else {
                      const newItem = {
                        id: session.id,
                        educator: session.educator,
                        name: "",
                        sun: [],
                        mon: [],
                        tue: [],
                        wed: [],
                        thu: [],
                        fri: [],
                        sat: [],
                        bgColor: "rgba(96, 31, 235, 1)",
                        bgColor2: "",
                        url: session.url,
                      };

                      const individualEvent = {
                        title: session.title,
                        start: session.start,
                        end: session.end,
                        timezone: session.timezone,
                      };

                      if (session.weekday === 0) {
                        newItem.sun = [individualEvent];
                      } else if (session.weekday === 1) {
                        newItem.mon = [individualEvent];
                      } else if (session.weekday === 2) {
                        newItem.tue = [individualEvent];
                      } else if (session.weekday === 3) {
                        newItem.wed = [individualEvent];
                      } else if (session.weekday === 4) {
                        newItem.thu = [individualEvent];
                      } else if (session.weekday === 5) {
                        newItem.fri = [individualEvent];
                      } else if (session.weekday === 6) {
                        newItem.sat = [individualEvent];
                      } else {
                        //
                      };

                      newEvents.push(newItem);
                    }
                  });

                  // setCalendarEvents(newEvents);

                  const filteredObjects = newEvents.filter((obj) =>
                    academyEducators.includes(obj.educator)
                  );

                  if (educatorId !== undefined && educatorId !== "") {
                    const filtered = filteredObjects.filter(
                      (event) => event.educator === educatorId
                    );
                    setFilteredEvents(filtered);
                  } else {
                    setFilteredEvents(filteredObjects);
                  }
                }
              } catch (error_2) {
                console.log(error_2);
              };
            };
          } else {
            console.log("Failed...");
          };
        } catch (error_1) {
          console.log(error_1);
        };
      };
    } catch (error) {
      console.log(error);
    };
  };

  const getEducatorNameFromId = (targetEducatorId) => {
    if (allEducators.length > 0) {
      const filtered = allEducators.filter(
        (educator) => educator.id === targetEducatorId
      );

      if (filtered.length > 0) {
        return filtered[0].name;
      } else {
        return targetEducatorId;
      };

    } else {
      return targetEducatorId;
    };
  };

  const getEducatorPhotoFromId = (targetEducatorId) => {
    if (allEducators.length > 0) {
      const filtered = allEducators.filter(
        (educator) => educator.id === targetEducatorId
      );
      if (filtered.length > 0) {
        return filtered[0].photo;
      } else {
        return targetEducatorId;
      }
    } else {
      return targetEducatorId;
    };
  };

  useEffect(() => {
    fetchAllEducators();
  }, []);

  return (
    <VStack py="50px" w="100%">
      <Stack
        w="100%"
        borderRadius="39px"
        bg="rgba(255, 255, 255, 1)"
        p={"15px"}
      >
        {/* Header */}
        <Stack
          bg="rgba(245, 245, 255, 1)"
          borderRadius="11px"
          flexDir={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          h="64px"
        >
          <Box
            className="title"
            w="20%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text textAlign={"center"}>Educators</Text>
          </Box>
          <Box
            className="day"
            w="11.42%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text>SUN</Text>
          </Box>
          <Box
            className="day"
            w="11.42%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text>MON</Text>
          </Box>
          <Box
            className="day"
            w="11.42%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text>TUE</Text>
          </Box>
          <Box
            className="day"
            w="11.42%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text>WED</Text>
          </Box>
          <Box
            className="day"
            w="11.42%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text>THU</Text>
          </Box>
          <Box
            className="day"
            w="11.42%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text>FRI</Text>
          </Box>
          <Box
            className="day"
            w="11.42%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text>SAT</Text>
          </Box>
        </Stack>

        {filteredEvents.map((item) => (
          <Stack
            bg="#fbf9ff"
            borderRadius="11px"
            flexDir={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap="0"
            marginY="5px"
            key={item.id}
          >
            <Box
              className="title"
              w="20%"
              textAlign={"center"}
              borderRight="1px solid rgba(216, 216, 255, 1)"
              h="113px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              cursor={"pointer"}
              onClick={() => selectedEducator(item.educator)}
            >
              <Box
                h="66px"
                width={"66px"}
                marginLeft="10px"
                display="flex"
                alignItems={"center"}
              >
                <Image
                  src={getEducatorPhotoFromId(item.educator)}
                  alt="avatar"
                  h="60px"
                  width={"60px"}
                  mx="auto"
                  borderRadius={"50%"}
                />
              </Box>

              <Text
                textAlign={"left"}
                w="70%"
                pl
                wordBreak="break-word"
                paddingLeft="14px"
                color="rgba(0, 0, 0, 1)"
                fontWeight={"500"}
                fontSize={"20px"}
              >
                {getEducatorNameFromId(item.educator)}
              </Text>
            </Box>
            <Box
              className="day"
              w="11.42%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              p="0"
              m="0"
              gap="0"
              h="114px"
              borderRight="1px solid rgba(216, 216, 255, 1)"
            >
              {item.sun.length > 0 ? (
                <Link onClick={() => selectedEvent(item)} cursor={"pointer"}>
                  <Box
                    bg={item.bgColor2 === "" ? item.bgColor : item.bgColor2}
                    w="100%"
                    width={"100%"}
                    minWidth={"100%"}
                    maxWidth={"100%"}
                    h="114px"
                    borderRadius={"10px"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    margin={0}
                    padding={8}
                  >
                    {item.sun.map((event) => (
                      <>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"14"}
                        >
                          {" "}
                          {/* {event.title} */}
                        </Text>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"10"}
                        >
                          {convertTimezone(event.start, event.timezone)} -{" "}
                          {convertTimezone(event.end, event.timezone) ?? "EST"}
                        </Text>
                      </>
                    ))}
                  </Box>
                </Link>
              ) : (
                ""
              )}
            </Box>
            <Box
              className="day"
              w="11.42%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRight="1px solid rgba(216, 216, 255, 1)"
              h="114px"
            >
              {item.mon.length > 0 ? (
                <Link onClick={() => selectedEvent(item)} cursor={"pointer"}>
                  <Box
                    bg={item.bgColor2 === "" ? item.bgColor : item.bgColor2}
                    w="100%"
                    width={"100%"}
                    minWidth={"100%"}
                    maxWidth={"100%"}
                    h="114px"
                    borderRadius={"10px"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    margin={0}
                    padding={8}
                  >
                    {item.mon.map((event) => (
                      <>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"14"}
                        >
                          {" "}
                          {/* {event.title} */}
                        </Text>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"10"}
                        >
                          {convertTimezone(event.start, event.timezone)} -{" "}
                          {convertTimezone(event.end, event.timezone) ?? "EST"}
                        </Text>
                      </>
                    ))}
                  </Box>
                </Link>
              ) : (
                ""
              )}
            </Box>
            <Box
              className="day"
              w="11.42%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRight="1px solid rgba(216, 216, 255, 1)"
              h="114px"
            >
              {item.tue.length > 0 ? (
                <Link onClick={() => selectedEvent(item)} cursor={"pointer"}>
                  <Box
                    bg={item.bgColor2 === "" ? item.bgColor : item.bgColor2}
                    w="100%"
                    width={"100%"}
                    minWidth={"100%"}
                    maxWidth={"100%"}
                    h="114px"
                    borderRadius={"10px"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    margin={0}
                    padding={8}
                  >
                    {item.tue.map((event) => (
                      <>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"14"}
                        >
                          {" "}
                          {/* {event.title} */}
                        </Text>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"10"}
                        >
                          {convertTimezone(event.start, event.timezone)} -{" "}
                          {convertTimezone(event.end, event.timezone) ?? "EST"}
                        </Text>
                      </>
                    ))}
                  </Box>
                </Link>
              ) : (
                ""
              )}
            </Box>
            <Box
              className="day"
              w="11.42%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRight="1px solid rgba(216, 216, 255, 1)"
              h="114px"
            >
              {item.wed.length > 0 ? (
                <Link onClick={() => selectedEvent(item)} cursor={"pointer"}>
                  <Box
                    bg={item.bgColor2 === "" ? item.bgColor : item.bgColor2}
                    w="100%"
                    width={"100%"}
                    minWidth={"100%"}
                    maxWidth={"100%"}
                    h="114px"
                    borderRadius={"10px"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    margin={0}
                    padding={8}
                  >
                    {item.wed.map((event) => (
                      <>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"14"}
                        >
                          {" "}
                          {/* {event.title} */}
                        </Text>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"10"}
                        >
                          {convertTimezone(event.start, event.timezone)} -{" "}
                          {convertTimezone(event.end, event.timezone) ?? "EST"}
                        </Text>
                      </>
                    ))}
                  </Box>
                </Link>
              ) : (
                ""
              )}
            </Box>
            <Box
              className="day"
              w="11.42%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRight="1px solid rgba(216, 216, 255, 1)"
              h="114px"
            >
              {item.thu.length > 0 ? (
                <Link onClick={() => selectedEvent(item)} cursor={"pointer"}>
                  <Box
                    bg={item.bgColor2 === "" ? item.bgColor : item.bgColor2}
                    w="100%"
                    width={"100%"}
                    minWidth={"100%"}
                    maxWidth={"100%"}
                    h="114px"
                    borderRadius={"10px"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    margin={0}
                    padding={8}
                  >
                    {item.thu.map((event) => (
                      <>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"14"}
                        >
                          {" "}
                          {/* {event.title} */}
                        </Text>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"10"}
                        >
                          {convertTimezone(event.start, event.timezone)} -{" "}
                          {convertTimezone(event.end, event.timezone) ?? "EST"}
                        </Text>
                      </>
                    ))}
                  </Box>
                </Link>
              ) : (
                ""
              )}
            </Box>
            <Box
              className="day"
              w="11.42%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRight="1px solid rgba(216, 216, 255, 1)"
              h="114px"
            >
              {item.fri.length > 0 ? (
                <Link onClick={() => selectedEvent(item)} cursor={"pointer"}>
                  <Box
                    bg={item.bgColor2 === "" ? item.bgColor : item.bgColor2}
                    w="100%"
                    width={"100%"}
                    minWidth={"100%"}
                    maxWidth={"100%"}
                    h="114px"
                    borderRadius={"10px"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    margin={0}
                    padding={8}
                  >
                    {item.fri.map((event) => (
                      <>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"14"}
                        >
                          {" "}
                          {/* {event.title} */}
                        </Text>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"10"}
                        >
                          {convertTimezone(event.start, event.timezone)} -{" "}
                          {convertTimezone(event.end, event.timezone) ?? "EST"}
                        </Text>
                      </>
                    ))}
                  </Box>
                </Link>
              ) : (
                ""
              )}
            </Box>
            <Box
              className="day"
              w="11.42%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRight="1px solid rgba(216, 216, 255, 1)"
              h="114px"
            >
              {item.sat.length > 0 ? (
                <Link onClick={() => selectedEvent(item)} cursor={"pointer"}>
                  <Box
                    bg={item.bgColor2 === "" ? item.bgColor : item.bgColor2}
                    w="100%"
                    width={"100%"}
                    minWidth={"100%"}
                    maxWidth={"100%"}
                    h="114px"
                    borderRadius={"10px"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    margin={0}
                    padding={8}
                  >
                    {item.sat.map((event) => (
                      <>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"14"}
                        >
                          {" "}
                          {/* {event.title} */}
                        </Text>
                        <Text
                          textAlign={"center"}
                          p={"2px"}
                          color={"#fff"}
                          fontSize={"10"}
                        >
                          {convertTimezone(event.start, event.timezone)} -{" "}
                          {convertTimezone(event.end, event.timezone) ?? "EST"}
                        </Text>
                      </>
                    ))}
                  </Box>
                </Link>
              ) : (
                ""
              )}
            </Box>
          </Stack>
        ))}
      </Stack>
    </VStack>
  );
};

export default Calender;
