import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'firebase/storage';
import { getFirestore, doc, collection, getDoc, getDocs, initializeFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAlfBPtlY7nQmGMXzuXIO0JdPUg9FTCrTw",
  authDomain: "houseglobal-3b11c.firebaseapp.com",
  projectId: "houseglobal-3b11c",
  storageBucket: "houseglobal-3b11c.appspot.com",
  messagingSenderId: "663104369348",
  appId: "1:663104369348:web:3d506df9585258ec670f91",
  measurementId: "G-7V1SBJ0X62"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const storage = getStorage(app);


export { app, analytics, firestore, storage }