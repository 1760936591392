import logoWhite from '../assets/img/JIFULogo-White.svg'
import search from '../assets/img/search-normal.svg'
import france from '../assets/img/france.png'
import germany from '../assets/img/germany.png'
import uk from '../assets/img/united-kingdom.png'
import us from '../assets/img/united-states.png'
import notification from '../assets/img/notification-bing.svg'
import profile from '../assets/img/profile-circle.svg'
import BannerBg from '../assets/img/bannerBg.jpg'
import FeatureLogo from '../assets/img/FeatureLogo.svg'
import feature1 from '../assets/img/feature1.svg'
import feature2 from '../assets/img/feature2.svg'
import feature3 from '../assets/img/feature3.svg'
import feature4 from '../assets/img/feature4.svg'
import cover1 from '../assets/img/Cover1.jpg'
import cover2 from '../assets/img/Cover2.jpg'
import cover3 from '../assets/img/Cover3.jpg'
import cover4 from '../assets/img/Cover4.jpg'
import cover5 from '../assets/img/Cover5.jpg'
import cover6 from '../assets/img/Cover6.jpg'
import chanelLogo1 from '../assets/img/chanelLogo1.svg'
import chanelLogo2 from '../assets/img/channelLogo2.svg'
import chanelLogo3 from '../assets/img/channelLogo3.svg'
import chanelLogo4 from '../assets/img/chanelLogo4.svg'
import chanelLogo5 from '../assets/img/chanelLogo5.svg'
import chanelLogo6 from '../assets/img/chanelLogo6.svg'
import arrow from '../assets/img/Arrow.svg'
import member1 from '../assets/img/member1.jpg'
import member2 from '../assets/img/member2.jpg'
import edu1 from '../assets/img/edu1.jpg'
import edu2 from '../assets/img/edu2.jpg'
import edu3 from '../assets/img/edu3.jpg'
import eduLogo1 from '../assets/img/eduLogo1.jpg'
import eduLogo2 from '../assets/img/eduLogo2.jpg'
import eduLogo3 from '../assets/img/eduLogo3.jpg'
import placeholderImage from '../assets/img/placeholder_image.png'
import placeholderCoverImage from '../assets/img/placeholder_image_course.png'
import blankUserImage from '../assets/img/blankuser_image.png'

import liveSearchBg from '../assets/img/liveSearchBg.jpg'

import liveLogo from '../assets/img/liveLogo.svg'
import avatar1 from '../assets/img/avatar/avatar1.png'
import avatar2 from '../assets/img/avatar/avatar2.png'
import avatar3 from '../assets/img/avatar/avatar3.png'
import avatar4 from '../assets/img/avatar/avatar4.png'
import avatar5 from '../assets/img/avatar/avatar5.png'
import avatar6 from '../assets/img/avatar/avatar6.png'
import avatar7 from '../assets/img/avatar/avatar7.png'
import avatar8 from '../assets/img/avatar/avatar8.png'
import avatar9 from '../assets/img/avatar/avatar9.png'
import avatar10 from '../assets/img/avatar/avatar10.png'
import special from '../assets/img/speaIMg.png'
import people from '../assets/img/people.svg'
import VideoFeature from '../assets/img/VideoFeature.png'
import avatarL from '../assets/img/avatarrl.png'
import play from '../assets/img/play.svg'
import record from '../assets/img/record.svg'
import refresh from '../assets/img/refresh.svg'
import VideoPlay from '../assets/img/VideoPlay.svg'
import VideoSlide1 from '../assets/img/VideoSlide1.jpg'
import VideoSlide2 from '../assets/img/VideoSlide2.jpg'
import VideoSlide3 from '../assets/img/VideoSlide3.jpg'
import chat from '../assets/img/chat.svg'
import chatOffline from '../assets/img/chat_offline.png'
import chatAvatar from '../assets/img/chatAvatar.png'
import typing from '../assets/img/typing.png'
import favorite from '../assets/img/favorite.svg'

import language_en from '../assets/img/language_en.png'
import language_es from '../assets/img/language_es.png'
import language_it from '../assets/img/language_it.png'
import language_de from '../assets/img/language_de.png'
import language_ru from '../assets/img/language_ru.png'
import language_fr from '../assets/img/language_fr.png'

import loginBg from '../assets/img/loginBg.jpg'
import loginLogo from '../assets/img/loginLogo.svg'

export const Img ={
    logoWhite,
    search,
    france,
    germany,
    uk,
    us,
    notification,
    profile,
    BannerBg,
    FeatureLogo,
    feature1,
    feature2,
    feature3,
    feature4,
    cover1,
    cover2,
    cover3,
    cover4,
    cover5,
    cover6,
    chanelLogo1,
    chanelLogo2,
    chanelLogo3,
    chanelLogo4,
    chanelLogo5,
    chanelLogo6,
    arrow,
    member1,
    member2,
    edu1,
    edu2,
    edu3,
    eduLogo1,
    eduLogo2,
    eduLogo3,
    placeholderImage,
    placeholderCoverImage,
    blankUserImage,
    liveSearchBg,

    language_en,
    language_es,
    language_it,
    language_de,
    language_ru,
    language_fr,

    liveLogo,
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
    avatar10,
    special,
    people,
    VideoFeature,
    avatarL,
    play,
    record,
    refresh,
    VideoPlay,
    VideoSlide1,
    VideoSlide2,
    VideoSlide3,
    chat,
    chatOffline,
    chatAvatar,
    typing,
    favorite,

    loginBg,
    loginLogo
}