import {
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { firestore } from "../../../util/firebase";
import { parseISO, subDays, isBefore } from 'date-fns';
import { limit, where, getDoc, setDoc, doc, query, collection } from "firebase/firestore";
import { Spinner } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { useRouter } from "../../../hooks/use-router";
import LoginMobileView from "./LoginMobileView/LoginMobileView";
import LoginDesktopView from "./LoginDesktopView/LoginDesktopView";

const Login = () => {
  const toast = useToast()
  const router = useRouter();
  const [isLoading, setIsloading] = useState(false)

  const tryUserSignIn = async (emailAddress, password) => {
    if (emailAddress === '' || password === '') {

      return;
    };
    try {
      fetch(
        "https://corsavailable.herokuapp.com/https://dsapi.directscale.com/v1/validate/login", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
          "Access-Control-Allow-Headers":
            "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version, Ocp-Apim-Subscription-Key",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": "1727ddb9a51c4679aebb1b47f5aabc39",
          "Origin": "",
          "X-Requested-With": ""
        },
        body: JSON.stringify({
          username: emailAddress,
          password: password,
        }),
      }
      ).then(async (data) => {
        console.log(data)
        const json = await data.json();
        const customerName = json["FirstName"];
        const customerLastName = json["LastName"];
        const customerId = json["CustomerId"];
        if (customerId !== undefined && customerId !== '') {
          checkUserSubscriptions(customerId, customerName, customerLastName);
        }
      }).catch((error) => {
        console.log(error);
        toast({
          title: 'Authentication Error',
          description: "Wrong credentials",
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      });
    } catch (error) {
      console.log(error)
    }
    setTimeout(() => {
      setIsloading(false)
    }, 2000);
  };

  const checkUserSubscriptions = (customerId, customerFirstName, customerLastName) => {

    const currentDate = new Date();
    const dateString = currentDate.toString();

    const poststring = {
      AssociateIds: [
        customerId
      ],
      DataPointsFilter: [
        {
          ID: "AssociateSubscriptions26ExpirationDate",
          Date: dateString
        },
        {
          ID: "CustomAssociateField7",
          Date: dateString
        }
      ]
    }
    fetch(
      "https://corsavailable.herokuapp.com/https://dsapi.directscale.com/v1/customers/customers-datapoints",
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,OPTIONS,PATCH,DELETE,POST,PUT",
          "Access-Control-Allow-Headers":
            "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version, Ocp-Apim-Subscription-Key",
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": "1727ddb9a51c4679aebb1b47f5aabc39",
          "Origin": "",
          "X-Requested-With": ""
        },
        body: JSON.stringify(poststring),
      }
    ).then(async (data) => {
      try {
        const json = await data.json();
        const dictionary = json[0];
        const dataPoints = dictionary["dataPoints"];
        if (dataPoints.length > 0) {
          // SUBSCRIPTION AVAILABLE
          const expirationDate = dataPoints[0];
          const teamAssociation = dataPoints[1];

          const expirationDateObject = parseISO(expirationDate);
          const expirationDate2DaysBefore = subDays(expirationDateObject, 2);
          // Compare the target date with the current date minus 2 days
          const isBeforeTwoDaysAgo = isBefore(currentDate, expirationDate2DaysBefore);
          if (isBeforeTwoDaysAgo === true) {
            userSubscribed(customerId, customerFirstName, customerLastName);
          } else {
            // showError("You don't have access to this platform.");
          };
        } else {
          // NO SUBSCRIPTIONS
        }
      } catch (error) {
        console.log(error);
        // showError("You don't have access to this platform.");
      };
    })
  };

  const selectUserTenant = async (associateId, customerId, customerFirstName, customerLastName) => {
    var targetId = "";

    if (associateId === "") {
      targetId = "1"
    } else {
      targetId = associateId
    }

    const tenantsRef = collection(firestore, "tenants");
    const q = query(tenantsRef, where("associate", "==", targetId), limit(1));
    try {
      const querySnapshot = await getDoc(q);
      if (!querySnapshot.empty) {
        const docSnap = querySnapshot.docs[0];
        const docData = docSnap.data();
        const selectedTenant = {
          id: docData.id,
          title: docData.title,
          navImage: docData.navimage,
          red: docData.red,
          green: docData.green,
          blue: docData.blue
        };
        localStorage.setItem("Tenant", JSON.stringify(selectedTenant));
        userSubscribed(customerId, customerFirstName, customerLastName);
      } else {
        // showError("Failed to fetch your team. Please try again later");
      }
    } catch (error) {
      // showError("Failed to fetch your team. Please try again later");
      // return null;
    }
  };

  const userSubscribed = (customerId, customerFirstName, customerLastName) => {
    // const q = query(
    //   collection(db, "members"),
    //   where("id", "==", "" + customerId),
    //   limit(1)
    // );
    (async () => {
      const docSnap = await getDoc(doc(firestore, "members", "" + customerId));
      if (docSnap.exists()) {
        localStorage.setItem("user", JSON.stringify(docSnap.data()));
        router.push("/");
      } else {
        setDoc(doc(firestore, "members", "" + customerId), {
          name: customerFirstName + " " + customerLastName,
          photo: "",
          permitted: true,
          id: "" + customerId
        }).then(function () {
          (async () => {
            const nestedDocSnap = await getDoc(doc(firestore, "members", "" + customerId));
            if (nestedDocSnap.exists()) {
              localStorage.setItem("user", JSON.stringify(nestedDocSnap.data()));
              router.push("/");
            }
          })();
        })
      }
    })();
  };

  useEffect(() => {

  }, []);

  return (
      <div>
        {isLoading &&
          <Box position="fixed" display='block' width="100%" height="100%" zIndex="99" textAlign="center" top="0" left="0">
            <Spinner style={{ position: "absolute", top: '50%', left: "50%" }} color="blue" size="xl" />
          </Box>
        }

        <LoginDesktopView tryUserSignIn={tryUserSignIn} isLoading={isLoading} setIsloading={setIsloading}/>
        <LoginMobileView tryUserSignIn={tryUserSignIn} isLoading={isLoading} setIsloading={setIsloading}/>
      </div>
  );
};

export default Login;
