import React, { useEffect, useState } from 'react'
import { VStack, Box, Text, Stack, Image } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { firestore } from '../../util/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Img } from '../../content/Content';

function FullScreenVideoPlayer() {

    const params = useParams();

    const [videoItem, setVideoItem] = useState();
    const [educatorData, setEducatorData] = useState();

    const fetchURLForItem = async () => {
        if (params.itemId !== undefined) {
            const recordedSessionDocRef = doc(firestore, 'recorded_sessions', params.itemId);

            await getDoc(recordedSessionDocRef).then((documentSnapshot) => {
                if (documentSnapshot.exists() === true) {
                    const data = documentSnapshot.data();
                    setVideoItem(data);
                    fetchEducator(data.educator);
                };
            });
        }; 
    };

    const fetchEducator = async (educator) => {
        if (educator !== undefined) {
            const educatorDocRef = doc(firestore, 'educators', educator);

            await getDoc(educatorDocRef).then((documentSnapshot) => {
                if (documentSnapshot.exists() === true) {
                    const data = documentSnapshot.data();
                    setEducatorData(data);
                };
            });
        }; 
    };

    useEffect(() => {
        fetchURLForItem();
    },[]);

    return (
        <div>
            <VStack
            bg={"rgba(245, 245, 255, 1)"}
            w="100%"
            h="auto"
            paddingBottom="0px">
                <VStack align={'start'} flexDir={'column'} w={'90%'} mt={'40px'} height={'auto'} paddingBottom={'40px'}>
                    <Text
                    mb="-40px"
                    color="black"
                    fontSize="28px"
                    fontWeight="600"
                    bottom={'0px'}
                    >
                    {videoItem && videoItem.title !== undefined ? videoItem.title : ''}

                    <Stack>
                    <Box
                        w="100%"
                        textAlign={"left"}
                        // borderRight="1px solid rgba(216, 216, 255, 1)"
                        h="auto"
                        display="flex"
                        alignItems="center"
                        justifyContent="start"
                        cursor={"pointer"}
                        pt={'10px'}
                        //   onClick={() => selectedEducator(item.educator)}
                        >
                        <Box
                            h="46px"
                            width={"46px"}
                            marginLeft="0px"
                            display="flex"
                            alignItems={"center"}
                        >
                            <Image
                              src={educatorData && educatorData.photo !== undefined ? educatorData.photo : Img.blankUserImage}
                            alt="avatar"
                            h="40px"
                            width={"40px"}
                            mx="auto"
                            borderRadius={"50%"}
                            />
                        </Box>

                        <Text
                            textAlign={"left"}
                            w="auto"
                            wordBreak="break-word"
                            paddingLeft="14px"
                            color="rgba(0, 0, 0, 1)"
                            fontWeight={"500"}
                            fontSize={"20px"}
                        >
                            {educatorData && educatorData.name !== undefined ? educatorData.name : 'User'}
                        </Text>
                    </Box>
                    </Stack>
                    </Text>
                    {videoItem !== undefined && videoItem.url !== undefined && (
                        <ReactPlayer controls={true} url={videoItem.url} height={'750px'} width={'100%'} style={{top:'0px'}}/>
                    )}
                </VStack>
            </VStack>
        </div>
    )
}

export default FullScreenVideoPlayer
