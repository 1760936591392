import React, { useEffect } from 'react'
import Banner from './components/Banner/Banner'
import Feature from './components/Feature/Feature'
import Channel from './components/chanel/Channel'
import Member from './components/member/Member'
import Education from './components/education/Education'
import SpecialChannel from './components/SpecialChannel/SpecialChannel'
import { useLocation } from 'react-router-dom'

function Home() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    },[pathname]);

    return (
        <div>
            <Banner/>
            <Feature/>
            <Channel/>
            {/* <Member/> */}
            {/* <Education/> */}
            {/* <SpecialChannel/> */}
        </div>
    )
}

export default Home
