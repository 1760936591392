import { Img } from "../../src/content/Content";

const languages = [
    {
        id: 'en',
        name: "English",
        flag: Img.language_en,
    },
    {
        id: 'es',
        name: "Spanish",
        flag: Img.language_es,
    },
    {
        id: 'fr',
        name: "French",
        flag: Img.language_fr,
    },
    {
        id: 'de',
        name: "German",
        flag: Img.language_de,
    },
    {
        id: 'ru',
        name: "Russian",
        flag: Img.language_ru,
    },
    {
        id: 'it',
        name: "Italian",
        flag: Img.language_it,
    },
];

const currentLanguage = () => {
    const defaultLanguage = localStorage.getItem('CurrentLanguage');

      if (defaultLanguage === null || defaultLanguage === undefined) {
        const englishDefault = languages.find((language) => language.id === 'en');
        return englishDefault;
      } else {
        const fetchedDefaultLanguage = languages.find((language) => language.id === defaultLanguage);
        return fetchedDefaultLanguage
      };
};

export { currentLanguage, languages }